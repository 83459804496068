import { withAsyncBoundary } from '@community-group/components';
import { SettingList } from '@community-group/components/features';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';
import { useSetParentingGroupStorage } from '@/hooks/useSetParentingGroupStorage';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

const MyGroupProfileSettingSection = () => {
  const { push } = useFlow();

  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();
  const { data: group } = useReadGroupDetail(groupId);

  useSetParentingGroupStorage();
  const { isKidProfileEnabled } = useGetParentingGroupStorage(groupId);

  return (
    <SettingList.Wrapper title="프로필">
      <SettingList.Item
        onClick={() => {
          push('GroupUserProfileDetailPage', {
            groupId,
            userId: currentUser.id.toString(),
          });
        }}
        title="내 프로필"
      />
      {group?.subNicknameSetting?.isSettingOn && (
        <SettingList.Item
          onClick={() => {
            push('GroupEditMemberSubNicknamePage', {
              groupId,
              userId: currentUser.id.toString(),
            });
          }}
          title="별명 설정"
        />
      )}
      {isKidProfileEnabled && (
        <SettingList.Item
          onClick={() => {
            push('ParentingGroupKidProfileListPage', {
              groupId,
              userId: currentUser.id.toString(),
            });
          }}
          title="내 아이 정보"
          description="대표 육아 모임으로 선정된 모임에서만 아이 정보를 입력해요."
        />
      )}
    </SettingList.Wrapper>
  );
};

export default withAsyncBoundary(MyGroupProfileSettingSection, {});
