import { ChallengeCertifiedActivitiesPresentation } from '@community-group/api/lib/group/models';
import { Divider, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import * as s from './style.css';

type Props = {
  myCertifyActivity: ChallengeCertifiedActivitiesPresentation;
  isCertifyEveryDay: boolean;
};

const MyChallengeStatus = ({ myCertifyActivity, isCertifyEveryDay }: Props) => {
  const enableChallengeCertifyNextStep = useFeatureFlag('enableChallengeCertifyNextStep');

  return (
    <div className={s.StatusWrapper}>
      <div className={s.StatusItemWrapper}>
        <Typography typography="caption2Regular" color="gray600">
          순위
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle1Bold" color="primary">
          {myCertifyActivity.currentRank}위
        </Typography>
      </div>
      <Divider
        size={1}
        direction="vertical"
        background={vars.$semantic.color.divider1}
        padding={0}
      />
      <div className={s.StatusItemWrapper}>
        <Typography typography="caption2Regular" color="gray600">
          이번 주 인증
        </Typography>
        <Spacing size={4} />
        <Typography typography="subtitle1Bold" color="gray700">
          {myCertifyActivity.intervalCertifiedProgress?.current}/
          {myCertifyActivity.intervalCertifiedProgress?.target}
        </Typography>
      </div>
      <Divider
        size={1}
        direction="vertical"
        background={vars.$semantic.color.divider1}
        padding={0}
      />
      {enableChallengeCertifyNextStep && isCertifyEveryDay ? (
        <div className={s.StatusItemWrapper}>
          <Typography typography="caption2Regular" color="gray600">
            연속 인증
          </Typography>
          <Spacing size={4} />
          <Typography typography="subtitle1Bold" color="gray700">
            {myCertifyActivity.continuousCertifiedProgress}회
            {myCertifyActivity.continuousCertifiedProgress > 0 && '🔥'}
          </Typography>
        </div>
      ) : (
        <div className={s.StatusItemWrapper}>
          <Typography typography="caption2Regular" color="gray600">
            전체 인증
          </Typography>
          <Spacing size={4} />
          <Typography typography="subtitle1Bold" color="gray700">
            {myCertifyActivity.totalCertifiedProgress?.current}/
            {myCertifyActivity.totalCertifiedProgress?.target}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MyChallengeStatus;
