import { BulletItem, Spacing, Typography } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';

import { CHALLENGE_PROMOTION_LANDKIT_LINK } from '@/features/ChallengePromotion/PromotionBanner/ChallengeListPromotionBanner';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { openLink } from '@/utils/link';

import * as s from './style.css';

const COIN_DROP_PNG =
  'https://asset-town.krrt.io/production/motion/b43d9ec8-7397-4235-91d3-1dc9b423fe5f/eaa375194e90fd0ecba8c9738775cf18c975ede1.png';

const ChallengePromotionBottomSheet = () => {
  const { pop } = useFlow();

  const handleOpenChallengePromotionLandkit = () => {
    openLink(CHALLENGE_PROMOTION_LANDKIT_LINK, true);
  };

  return (
    <BottomSheet
      style={{
        padding: 0,
      }}
    >
      <div className={s.Container}>
        <IconXmarkLine
          size={24}
          color={vars.$scale.color.gray900}
          className={s.CloseIcon}
          onClick={pop}
        />
        <div className={s.EventChip}>
          <Typography typography="subtitle2Bold" color="staticWhite">
            EVENT
          </Typography>
        </div>
        <Spacing size={8} />
        <Typography typography="h4" textAlign="center" style={{ zIndex: 1000 }}>
          챌린지에 인증글 남기고
          <br />
          당근머니 받아가세요!
        </Typography>
        <Spacing size={8} />
        <Typography
          typography="subtitle2Regular"
          color="gray600"
          className={s.MoreButton}
          onClick={handleOpenChallengePromotionLandkit}
        >
          이벤트 자세히 보기
        </Typography>
        <Spacing size={8} />
        <img src={COIN_DROP_PNG} alt="동전지갑 이미지" className={s.CoinWalletImage} />

        <Spacing size={24} />
        <div className={s.ListContainer}>
          <div className={s.NumberContainer}>
            <Typography typography="caption1Bold" color="gray700">
              1
            </Typography>
          </div>
          <div className={s.ListItem}>
            <Typography typography="bodyL1Regular" color="gray900">
              모임에서 챌린지를 직접 만들거나 참여해요
            </Typography>
          </div>
        </div>
        <Spacing size={12} />
        <div className={s.ListContainer}>
          <div className={s.NumberContainer}>
            <Typography typography="caption1Bold" color="gray700">
              2
            </Typography>
          </div>
          <div className={s.ListItem}>
            <Typography typography="bodyL1Regular" color="gray900">
              참여한 챌린지에 인증글을 남기고, 지급된 당근머니를 확인해보세요!
            </Typography>
          </div>
        </div>
        <Spacing size={16} />
        <div className={s.CalloutContainer}>
          <Typography typography="bodyL2Bold" color="gray900">
            Q. 챌린지가 무엇인가요?
          </Typography>
          <Typography typography="bodyL2Regular" color="gray900">
            모임 멤버들과 함께 목표를 설정하고, 꾸준히 인증글을 올려 목표를 달성하는 기능이에요.
          </Typography>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ChallengePromotionBottomSheet;
