import { groupClient } from '@community-group/api';
import { useSuspenseQuery } from '@tanstack/react-query';

import { GROUP_DETAIL_QUERY_KEY } from '@/domain/GroupDetail/queries';
import { useAsyncRead } from '@/shared/api/hooks/useAsyncRead';

export const useQueryNullableGroupDetail = (groupId?: string) => {
  const read = useAsyncRead(groupClient.api.GroupApi.apiV1GroupsIdGet);
  return {
    queryKey: groupId ? GROUP_DETAIL_QUERY_KEY.base(groupId) : [],
    queryFn: () => {
      if (!groupId) return null;
      return read({ id: Number(groupId) }).then((res) => res.group);
    },
  };
};

/**
 * groupId가 nullable한 상태일 때 사용하는 useReadGroupDetail
 * groupId가 존재하는 경우 기존의 훅과 동일한 쿼리키를 바라봐요.
 * @param groupId
 * @returns GroupDetailPresentation
 */
export const useReadNullableGroupDetail = (groupId?: string) => {
  const query = useQueryNullableGroupDetail(groupId);
  return useSuspenseQuery({ ...query });
};
