import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import useCheckNeedToSetCategoryBottomSheet from '@/components/group/Setting/hooks/useCheckNeedToSetCategoryBottomSheet';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import GroupInfoSection from '@/features/GroupSetting/GroupInfo';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { LayoutWrapper } from '@/stackflow/components/Layout';

import * as s from './style.css';

type Props = {
  groupId: string;
  scrollToBottom?: 'true' | 'false' | undefined;
};
const GroupSettingBase = ({ groupId, scrollToBottom }: Props) => {
  const layoutWrapperRef = useRef(null);
  const [{ data: currentUser }, { data: groupDetail }] = useSuspenseQueries({
    queries: [useQueryGroupMe({ groupId }), useQueryGroupDetail(groupId)],
  });
  useEffect(() => {
    if (scrollToBottom === 'true') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      layoutWrapperRef.current.scrollTop = layoutWrapperRef.current.scrollHeight;
    }
  }, [scrollToBottom, layoutWrapperRef]);

  const checkSetCategory = useCheckNeedToSetCategoryBottomSheet({ groupId });

  useDidMountSequentialProcess([checkSetCategory]);

  return (
    <LayoutWrapper ref={layoutWrapperRef} className={s.Wrapper}>
      <GroupInfoSection groupId={groupId} currentUser={currentUser} groupDetail={groupDetail} />
    </LayoutWrapper>
  );
};

export default GroupSettingBase;
