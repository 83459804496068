import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import { Render, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { useSuspenseQueries } from '@tanstack/react-query';
import { MouseEventHandler, useMemo } from 'react';

import GroupMemberGradeMissionBanner from '@/components/common/GroupMemberGradeMissionBanner';
import MemberMissionBanner from '@/components/common/MemberMissionBanner';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMemberGradeApplicationStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeApplicationStatus';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import useMemberGradeMissionList from '../../hooks/useMemberGradeMissionList';
import useMemberMissionList from '../../hooks/useMemberMissionList';

type Props = {
  groupId: string;
  currentUser: GroupCurrentUser;
  onClick: MouseEventHandler;
};

const SidebarBannerForMember = ({ groupId, currentUser, onClick }: Props) => {
  const { push } = useFlow();

  const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
  const [{ data: group }, { data: memberGradeStatus }, { data: isApplicationPending }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupDetail(groupId),
        useQueryGroupMemberGradeStatus(groupId),
        useQueryGroupMemberGradeApplicationStatus({
          groupId: groupId,
          userId: currentUser.id.toString(),
        }),
      ],
    });

  const handleMemberGradeApplicationClick = async () => {
    trackEvent({
      event: 'click_apply_member_grade_upgrade',
      params: {
        groupId,
        userId: currentUser.id.toString(),
        groupName: group.name,
        categoryId: group.category.id,
        categoryName: group.category.name,
        from: 'sidebar',
      },
      sample: true,
    });

    push('GroupMemberGradeDetailPage', {
      groupId: groupId,
      userId: currentUser.id.toString(),
      isBannerApplication: 'true',
      from: 'sidebar',
    });
  };

  const showMemberGradeBanner = useMemo(() => {
    return currentUser.grade.name === '준회원' && !isApplicationPending;
  }, [currentUser.grade.name, isApplicationPending]);

  const memberGradeEnabled = useMemo(() => {
    return memberGradeEnabledFeatureFlag && memberGradeStatus === 'system';
  }, [memberGradeEnabledFeatureFlag, memberGradeStatus]);

  return memberGradeEnabled ? (
    <Render condition={showMemberGradeBanner}>
      <SidebarGroupMemberGradeMissionBanner
        groupId={groupId}
        currentUser={currentUser}
        onClick={onClick}
        onMemberGradeApplicationClick={handleMemberGradeApplicationClick}
      />
    </Render>
  ) : (
    <SidebarGroupMemberMissionBanner
      groupId={groupId}
      currentUser={currentUser}
      onClick={onClick}
    />
  );
};

type MemberGradeMissionBannerProps = {
  onMemberGradeApplicationClick: () => Promise<void>;
} & Props;

const SidebarGroupMemberGradeMissionBanner = ({
  groupId,
  currentUser,
  onClick,
  onMemberGradeApplicationClick,
}: MemberGradeMissionBannerProps) => {
  const missionList = useMemberGradeMissionList({
    groupId: groupId,
    userId: currentUser.id.toString(),
  });

  const isMissionCompleted = useMemo(() => {
    return missionList.every(({ isCompleted }) => isCompleted);
  }, [missionList]);

  const title = useMemo(() => {
    if (isMissionCompleted) return '등업 신청하기';

    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    return mission?.title ?? '멤버 등업 미션';
  }, [missionList, isMissionCompleted]);

  const progress = useMemo(() => {
    const activityCount = missionList.reduce((acc, mission) => {
      const count = mission.isCompleted ? mission.goalCount : mission.count;
      return acc + count;
    }, 0);
    const totalGoalCount = missionList.reduce((acc, mission) => acc + mission.goalCount, 0);
    const value = (activityCount / totalGoalCount) * 100;

    return Math.round(value / 10) * 10;
  }, [missionList]);

  const handleBannerClick = async (e) => {
    onClick(e);

    if (isMissionCompleted) {
      await onMemberGradeApplicationClick();
      return;
    }

    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    mission?.onClick();
  };

  return (
    <GroupMemberGradeMissionBanner
      title={title}
      progress={progress}
      size="small"
      onClick={handleBannerClick}
      actionButton={<IconChevronRightFill size={16} color="gray600" />}
    />
  );
};

const SidebarGroupMemberMissionBanner = ({ groupId, onClick }: Props) => {
  const missionList = useMemberMissionList(groupId);

  const title = useMemo(() => {
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    return mission?.title ?? '모임 가입 미션';
  }, [missionList]);

  const progress = useMemo(() => {
    const completedMissionCount = missionList.filter(({ isCompleted }) => isCompleted).length;
    const totalMissionCount = missionList.length;
    const value = (completedMissionCount / totalMissionCount) * 100;

    return Math.round(value / 10) * 10;
  }, [missionList]);

  const handleBannerClick = (e) => {
    onClick(e);
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    mission?.onClick();
  };

  if (missionList.length === 0) return null;
  if (missionList.every(({ isCompleted }) => isCompleted)) return null;

  return (
    <MemberMissionBanner
      title={title}
      progress={progress}
      size="small"
      onClick={handleBannerClick}
      actionButton={<IconChevronRightFill size={16} color="gray600" />}
    />
  );
};

export default withAsyncBoundary(SidebarBannerForMember, {});
