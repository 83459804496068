import {
  Divider,
  Spacing,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { AppScreen } from '@stackflow/plugin-basic-ui';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useGetGroupFeedSummaryMeetup } from '@/api/hooks/useGetGroupFeedSummaryMeetup';
import { useGetGroupSuperHostDetail } from '@/api/hooks/useGetGroupSuperHostDetail';
import TransparentNavTitleShowObserver from '@/components/common/TransparentNavScreen/TransparentNavTitleShowObserver';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMembers } from '@/domain/GroupMember/hooks/useReadGroupMembers';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import FixedBottomSection from '../../Detail/components/FixedBottomSection';
import RecommendSimilarGroupSection from '../../Detail/components/Home/components/RecommendSimilarGroupSection';
import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupDetailSkeleton from '../../Detail/pages/Skeleton';
import DetailNotMemberFeedSummary from '../../DetailNotMember/components/DetailNotMemberFeedSummary';
import GroupInfoSection from '../../DetailNotMember/components/GroupInfoSection';
import HostInfoSection from '../../DetailNotMember/components/HostInfoSection';
import MeetupSection from '../../DetailNotMember/components/MeetupSection';
import MemberSection from '../../DetailNotMember/components/MemberSection';
import { validHostInfoData } from '../../DetailNotMember/utils/validData';
import GroupDetailHeroSlider from '../../ImageViewerPage/components/GroupDetailHeroSlider';
import GroupDetailAppBarMenu from '../components/GroupDetailAppBarMenu';
import GroupDetailAppScreen from '../components/GroupDetailAppScreen';
import { GroupDetailContext } from '../components/GroupDetailContext';
import GroupDetailPreviewPageButton from '../components/GroupDetailPreviewPageButton';
import PreviewPageGuideBanner from '../components/PreviewPageGuideBanner';

type GroupDetailPreviewPageParams = Pick<
  PageParams,
  | 'groupId'
  | 'activeTab'
  | 'isNew'
  | 'from'
  | 'requestMeetupReviewId'
  | 'boardCategoryKey'
  | 'applicationStatus'
  | 'openSidebar'
>;

// 새로운 미가입자 뷰 미리보기 페이지
const GroupDetailPreviewPage: ActivityComponentType<GroupDetailPreviewPageParams> = () => {
  const { groupId } = usePathParams();

  const [
    { data: groupMe },
    { data: group },
    {
      data: { members },
    },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupMe({ groupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupMembers({
        groupId,
        roles: ['manager', 'member'],
        limit: 3,
      }),
    ],
  });

  const { data: superHostInfo } = useGetGroupSuperHostDetail(groupId);
  const { data: feedMeetups } = useGetGroupFeedSummaryMeetup(Number(groupId));

  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId, onlyPublic: true });

  if (!group) return <GroupDetailSkeleton />;

  const sections = [
    {
      order: 1,
      condition: isExistHeroSlider,
      component: <GroupDetailHeroSlider groupId={groupId} initialIndex={0} role={'none'} />,
    },
    {
      order: 2,
      condition: group,
      component: <PreviewPageGuideBanner />,
    },
    {
      order: 3,
      condition: group,
      component: (
        <>
          <TransparentNavTitleShowObserver />
          <GroupInfoSection group={group} />
        </>
      ),
    },
    {
      order: 5,
      dividerHeight: 1,
      condition: validHostInfoData(superHostInfo),
      component: superHostInfo && <HostInfoSection groupId={groupId} hostInfo={superHostInfo} />,
    },
    {
      order: 6,
      dividerHeight: 1,
      condition: members.length > 0,
      component: (
        <MemberSection groupId={groupId} members={members} group={group} currentUser={groupMe} />
      ),
    },
    {
      order: 7,
      dividerHeight: 1,
      condition: feedMeetups,
      component: (
        <MeetupSection
          feedMeetup={feedMeetups}
          groupId={groupId}
          group={group}
          shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
        />
      ),
    },
    {
      order: 8,
      dividerHeight: 1,
      condition: groupMe,
      component: <DetailNotMemberFeedSummary group={group} currentUser={groupMe} />,
    },
    {
      order: 9,
      condition: true,
      dividerHeight: 8,
      dividerPadding: 0,
      component: <RecommendSimilarGroupSection categoryId={group.category.id} />,
    },
  ];

  return (
    <GroupDetailContext>
      <GroupDetailAppScreen
        isExistHeroSlider={isExistHeroSlider}
        title={group.name}
        transparent={isExistHeroSlider}
        appendRight={({ iconColor }) => (
          <GroupDetailAppBarMenu
            group={group}
            currentUserRole={groupMe.role}
            iconColor={iconColor}
          />
        )}
      >
        <GroupDetailPreviewPageButton />
        {sections.map((section) => {
          if (!section.condition) return null;
          return (
            <div key={section.order}>
              {section?.dividerHeight && (
                <Divider size={section.dividerHeight} padding={section?.dividerPadding ?? 16} />
              )}
              {section.component}
            </div>
          );
        })}
        <Spacing size={120} />
        <FixedBottomSection enableNeedVerificationNotice={false} />
      </GroupDetailAppScreen>
    </GroupDetailContext>
  );
};

export default withAsyncBoundary(GroupDetailPreviewPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
