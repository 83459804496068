import { vars } from '@seed-design/design-token';
import { QueryClient } from '@tanstack/react-query';
import type VConsole from 'vconsole';

import { getXpropertyPath, XpropertyData } from '@/api/base/xproperty';
import { bridge } from '@/contexts/Bridge';
import { FeatureFlagKeysType } from '@/hooks/useFeatureFlag';

const XPROPERTY_QUERY_KEY = [getXpropertyPath];

export const XPROPERTY_PLUGIN_ID = 'feature_flags';
export const XPROPERTY_PLUGIN_NAME = 'Feature Flags';

const RESET_WARNING = `⚠️ xProperty 값은 다음의 경우에 초기화됩니다.
- 웹뷰를 닫을 때 / 프리뷰 변경시
- 초기화 버튼을 누를 때
- 앱을 종료했다 다시 실행할 때
🥕 변경 값은 다른 유저와 기기에서는 영향이 없어요. 편하게 사용해주세요.`;

export const initXpropertyTempHandlerPlugin = (
  vConsoleModule: typeof VConsole,
  queryClient: QueryClient
) => {
  let initialData: XpropertyData | null = null;
  const xpropertyPlugin = new vConsoleModule.VConsolePlugin(
    XPROPERTY_PLUGIN_ID,
    XPROPERTY_PLUGIN_NAME
  );

  const setXpropertyQueryDefaultsConfig = (isManualOverride: boolean) => {
    queryClient.setQueryDefaults(XPROPERTY_QUERY_KEY, {
      staleTime: isManualOverride ? Infinity : 1000 * 10,
      refetchOnMount: !isManualOverride,
      refetchOnReconnect: !isManualOverride,
      refetchOnWindowFocus: !isManualOverride,
    });
  };

  const updateToggleStates = (flags: FeatureFlagKeysType = {}) => {
    Object.entries(flags).forEach(([key, value]) => {
      const element = document.getElementById(key) as HTMLInputElement;
      if (!element) return;

      if (typeof value === 'boolean') {
        element.checked = value;
      } else if (typeof value === 'number') {
        element.value = (value as number).toString();
      }
    });
  };

  xpropertyPlugin.on('addTool', (callback) => {
    const applyButton = {
      name: '적용하기',
      onClick: function (event) {
        const currentData = queryClient.getQueryData<XpropertyData>(XPROPERTY_QUERY_KEY);
        setXpropertyQueryDefaultsConfig(true);
        const updatedFlags = getFormValues(currentData?.communityGroupWebFeatureFlags);
        const currentXProperty = queryClient.getQueryData<XpropertyData>(XPROPERTY_QUERY_KEY);

        if (currentXProperty) {
          queryClient.setQueryData(XPROPERTY_QUERY_KEY, {
            ...currentXProperty,
            communityGroupWebFeatureFlags: {
              ...currentXProperty.communityGroupWebFeatureFlags,
              ...updatedFlags,
            },
          });
        }

        bridge.openToast({
          toast: {
            body: '적용되었습니다.',
          },
        });
      },
    };

    const resetButton = {
      name: '초기화',
      onClick: function (event) {
        setXpropertyQueryDefaultsConfig(false);
        if (initialData) {
          queryClient.setQueryData(XPROPERTY_QUERY_KEY, initialData);
          updateToggleStates(initialData.communityGroupWebFeatureFlags);
          queryClient.invalidateQueries({ queryKey: XPROPERTY_QUERY_KEY });
        }
      },
    };

    callback([applyButton, resetButton]);
  });

  xpropertyPlugin.on('renderTab', (callback) => {
    const currentData = queryClient.getQueryData<XpropertyData>(XPROPERTY_QUERY_KEY);
    if (!initialData && currentData) {
      initialData = JSON.parse(JSON.stringify(currentData));
    }

    const view = `<div class="feature-flag-container"></div>`;
    callback(view);
  });

  xpropertyPlugin.on('show', () => {
    const currentData = queryClient.getQueryData<XpropertyData>(XPROPERTY_QUERY_KEY);
    if (!initialData && currentData) {
      initialData = JSON.parse(JSON.stringify(currentData));
    }

    const view = featureFlagTemplate(currentData?.communityGroupWebFeatureFlags);

    const featureFlagContainer = document.querySelector('.feature-flag-container') as HTMLElement;
    featureFlagContainer.innerHTML = view;
  });

  return xpropertyPlugin;
};

const featureFlagTemplate = (currentFlags: FeatureFlagKeysType = {}) => {
  const flagElements = Object.entries(currentFlags)
    .map(([key, value]) => {
      const isBoolean = typeof value === 'boolean';

      return `
      <div style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        margin-bottom: 8px;
        background: ${vars.$scale.color.gray50};
        border-radius: 8px;
      ">
        <div style="
          display: flex;
          align-items: center;
          gap: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        ">
          <span style="
            font-size: 14px;
            color: ${vars.$scale.color.gray900};
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          ">${key}</span>
        </div>
        ${
          isBoolean
            ? `<label class="toggle-switch">
                <input
                  type="checkbox"
                  id="${key}"
                  ${value ? 'checked' : ''}
                />
                <span class="toggle-slider"></span>
              </label>`
            : `<input
                type="number"
                id="${key}"
                value="${value || ''}"
                style="
                  width: 60px;
                  padding: 8px;
                  border: 1px solid ${vars.$scale.color.gray200};
                  border-radius: 4px;
                  text-align: center;
                "
              />`
        }
      </div>
    `;
    })
    .join('');

  return `
    <style>
       .warning-callout {
        background-color: ${vars.$scale.color.yellow50};
        border-radius: 8px;
        padding: 12px 16px;
        margin-bottom: 24px;
        white-space: pre-line;
        color: ${vars.$scale.color.gray900};
        font-size: 14px;
      }
      .toggle-switch {
        position: relative;
        display: inline-block;
        width: 44px;
        height: 24px;
        min-width: 44px;
      }
      .toggle-switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .toggle-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${vars.$scale.color.gray200};
        transition: .4s;
        border-radius: 34px;
      }
      .toggle-slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
      }
      input:checked + .toggle-slider {
        background-color: ${vars.$semantic.color.primary};
      }
      input:checked + .toggle-slider:before {
        transform: translateX(20px);
      }
    </style>
    <div style="width: 100%; padding: 16px;">
      <h1 style="
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 600;
        color: ${vars.$scale.color.gray900};
      ">Feature Flag Handler</h1>
      <div class="warning-callout">${RESET_WARNING}</div>
      <div style="margin-bottom: 24px;">
        ${flagElements}
      </div>
    </div>
  `;
};

const getFormValues = (currentFlags: FeatureFlagKeysType = {}): FeatureFlagKeysType => {
  const updatedFlags: FeatureFlagKeysType = {};

  Object.keys(currentFlags).forEach((key) => {
    const element = document.getElementById(key) as HTMLInputElement;
    if (!element) return;

    const currentValue = currentFlags[key];
    const isBoolean = typeof currentValue === 'boolean';

    if (isBoolean) {
      updatedFlags[key] = element.checked;
    } else if (typeof currentValue === 'number') {
      const numberValue = parseInt(element.value, 10);
      if (!isNaN(numberValue)) {
        updatedFlags[key] = numberValue;
      }
    }
  });

  return updatedFlags;
};
