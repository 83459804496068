import { BoxButton } from '@community-group/components';
import { AsyncBoundary } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { usePostBoardCategory } from '@/api/hooks/usePostBoardCategory';
import { FormInput } from '@/components/common/base/Input/Text';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { groupBoardCategoryFieldSchema } from '@/utils/validate/formSchema/boardCategory';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

import * as s from './createBoardCategoryBottomSheet.css';

export type GroupSettingCreateBoardCategoryBottomSheetParams = Pick<PageParams, 'groupId'>;

const GroupSettingCreateBoardCategoryBottomSheet: ActivityComponentType<
  GroupSettingCreateBoardCategoryBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <CreateBoardCategoryModalWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const CreateBoardCategoryModalWrapper = () => {
  const { groupId } = usePathParams();

  const { data: group } = useReadGroupDetail(groupId);

  const handleErrorWithToast = useHandleErrorWithToast();

  const { watch, register } = useForm();

  const { pop } = useConnectedActions();

  const nameFieldValue = watch('name');
  const { mutate } = usePostBoardCategory({
    onError: handleErrorWithToast,
    onSuccess: ({ data }) => {
      trackEvent({
        event: 'add_board_category',
        params: {
          groupId,
          groupName: group?.name,
          groupCategoryName: group?.category.name,
          boardCategoryName: nameFieldValue,
        },
        loggerType: ['AMPLITUDE', 'FIREBASE'],
      });
      trackEvent({
        event: 'click_add_board_category',
        params: {
          groupId,
          groupName: group?.name,
          groupCategoryName: group?.category.name,
          boardCategoryName: nameFieldValue,
        },
        loggerType: ['KARROT'],
      });

      pop().send({
        data,
      });
    },
  });

  const isButtonDisabled = useMemo(() => {
    if (!nameFieldValue) return true;
    if (nameFieldValue.trim().length === 0) return true;

    return false;
  }, [nameFieldValue]);

  const validateForm = () => {
    if (!validateSchemaWithHandleToast(groupBoardCategoryFieldSchema.name, nameFieldValue))
      return false;

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    handleCreateBoardCategory();
  };

  const handleCreateBoardCategory = () => {
    mutate({
      id: Number(groupId),
      boardCategoryCreateForm: {
        boardCategories: [
          {
            category: nameFieldValue,
            type: 'custom',
          },
        ],
      },
    });
  };

  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>새로운 게시판</h3>
      <Spacing size={16} />
      <FormInput
        register={register}
        name="name"
        placeholder="게시판 제목을 입력해주세요."
        maxLength={24}
        value={nameFieldValue}
      />
      <Spacing size={16} />
      <BoxButton width="100%" size="xlarge" isDisabled={isButtonDisabled} onClick={handleSubmit}>
        만들기
      </BoxButton>
    </div>
  );
};

export default GroupSettingCreateBoardCategoryBottomSheet;
