import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import {
  Spacing,
  Switch,
  Typography,
  useSnackbarAdapter,
  VerticalSpacing,
} from '@community-group/components';
import { IconBellLine } from '@daangn/react-monochrome-icon';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import usePatchChallengeNotificationSetting from '@/domain/Challenge/hooks/usePatchChallengeNotificationSetting';
import { useQueryChallengeNotificationSetting } from '@/domain/Challenge/hooks/useReadChallengeNotificationSetting';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';

import * as s from './style.css';

type Props = {
  groupId: number;
  challengeDetail: ChallengeDetailPresentation;
};

const RemindNotificationSection = ({ groupId, challengeDetail }: Props) => {
  const handleErrorWithToast = useHandleErrorWithToast();
  const snackbarAdapter = useSnackbarAdapter();
  const [notificationSetting, setNotificationSetting] = useState<boolean>(false);

  const [{ data: notificationSettingData, refetch: refetchNotificationSetting }, { data: group }] =
    useSuspenseQueries({
      queries: [
        useQueryChallengeNotificationSetting({ groupId, challengeId: challengeDetail.id }),
        useQueryGroupDetail(groupId.toString()),
      ],
    });

  const { mutate: patchNotificationSetting } = usePatchChallengeNotificationSetting({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchNotificationSetting();
      const turnOn = notificationSetting;
      trackEvent({
        event: turnOn
          ? 'click_challenge_reminder_notification_on'
          : 'click_challenge_reminder_notification_off',
        params: {
          groupId,
          challengeId: challengeDetail.id,
          challengeName: challengeDetail.name,
          groupName: group?.name,
          categoryId: group?.category.id,
        },
      });
      snackbarAdapter.dismiss();
      snackbarAdapter.create({
        message: turnOn ? '인증 리마인드 알림을 받아요.' : '인증 리마인드 알림을 받지 않아요.',
        actionLabel: turnOn ? '알림끄기' : undefined,
        onAction: turnOn
          ? () => {
              setNotificationSetting(false);
              patchNotificationSetting({
                id: groupId,
                challengeId: challengeDetail.id,
                form: { isReminderEnabled: false },
              });
            }
          : undefined,
        onClick: snackbarAdapter.dismiss,
        shouldCloseOnAction: true,
        type: 'default',
        timeout: 3000,
      });
    },
  });

  useEffect(() => {
    setNotificationSetting(notificationSettingData?.isReminderEnabled ?? false);
  }, [notificationSettingData]);

  if (!challengeDetail) return null;

  return (
    <div className={s.Wrapper}>
      <div className={s.MainWrapper}>
        <div className={s.IconWrapper}>
          <IconBellLine size={24} />
        </div>
        <VerticalSpacing size={12} />
        <div className={s.TitleWrapper}>
          <Typography typography="bodyM1Regular">인증 리마인드 알림</Typography>
          <Spacing size={2} />
          <Typography typography="caption1Regular" color="gray600">
            인증을 잊지 않도록 알림을 보내드려요.
          </Typography>
        </div>
      </div>
      <div className={s.SwitchWrapper}>
        <Switch
          isSelected={notificationSetting ?? false}
          onChange={(value) => {
            setNotificationSetting(value);
            patchNotificationSetting({
              id: groupId,
              challengeId: challengeDetail.id,
              form: { isReminderEnabled: value },
            });
          }}
        />
      </div>
    </div>
  );
};

export default RemindNotificationSection;
