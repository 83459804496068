import { GroupLevelUpMissions } from '@community-group/api/lib/group/models';
import {
  Spacing,
  Typography,
  VerticalSpacing,
  withAsyncBoundary,
} from '@community-group/components';
import {
  IconChevronDownFill,
  IconChevronRightFill,
  IconChevronUpFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import useMainBannerFolded from '@/components/group/DetailV2/hooks/useMainBannerFolded';
import useMissionClickHandler from '@/components/group/GroupLevel/hooks/useMissionClickHandler';
import { getUnCompletedMissions } from '@/components/group/GroupLevel/utils/levelMissionUtils';
import { MISSION_INFO } from '@/components/group/GroupLevel/utils/missions';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupLevelProgress from './GroupLevelProgress';
import * as s from './index.css';
import MissionItem from './MissionItem';

type Props = PropsWithChildren<{
  groupId: string;
  onClick?: () => void;
  size: 'small' | 'large';
}>;

const GroupLevelBanner = ({ groupId, onClick, size = 'large' }: Props) => {
  const { foldedMainBanner, handleToggle } = useMainBannerFolded();
  const isLarge = size === 'large';

  const defaultFoldedValue = isLarge ? foldedMainBanner : true;
  const [fold, setFold] = useState<boolean>(defaultFoldedValue);

  useEffect(() => {
    setFold(defaultFoldedValue);
  }, [defaultFoldedValue]);

  const { data: level } = useGetGroupLevel({ groupId: Number(groupId) });
  const { data: group } = useReadGroupDetail(groupId);
  const { push } = useFlow();

  const {
    userConfig: { userId },
  } = useUserConfig();
  const { handleMissionClick } = useMissionClickHandler({ groupId });

  const handleFold = () => {
    if (onClick) return onClick();
    handleToggle();
    setFold((prev) => !prev);
  };

  const handleMoveToLevelDetail = () => {
    const uncompletedMissions = getUnCompletedMissions(level?.forLevelUp.missions);
    trackEvent({
      event: 'click_move_to_level_detail',
      params: {
        currentLevel: level?.currentLevel,
        currentProgressPercentage: level?.forLevelUp.percentage,
        size,
        groupId,
        userId: (userId ?? 0).toString(),
        uncompletedMissionCount: uncompletedMissions.length,
        nextPeriodExpectation: level?.forMaintain?.nextPeriodExpectation,
        forMaintainActivityPointAchieve: level?.forMaintain?.missions?.activityPoint?.achieveCount,
        forMaintainActivityPointCurrent: level?.forMaintain?.missions?.activityPoint?.currentCount,
        categoryId: group.category.id,
        categoryName: group.category.name,
      },
    });

    push('GroupLevelDetailPage', {
      groupId,
    });
  };

  const titleText = useMemo(() => {
    if (!isLarge) return '모임 레벨 확인하기';
    if (level?.currentLevel === 5) return 'Lv.5 유지 미션';
    return '모임 레벨업 미션';
  }, [level?.currentLevel, isLarge]);

  const remainingProgressPercentage = level ? 100 - Math.floor(level.forLevelUp.percentage) : 0;

  const levelText = useMemo(() => {
    if (level?.currentLevel === 5 && remainingProgressPercentage === 0)
      return `모든 미션을 완료했어요!`;
    if (level?.currentLevel === 5) return `미션 완료까지 ${remainingProgressPercentage}% 남았어요!`;
    return `다음 레벨까지 ${remainingProgressPercentage}% 남았어요!`;
  }, [level?.currentLevel, remainingProgressPercentage]);

  if (!level) return null;

  const levelUpMissions = Object.entries(level.forLevelUp.missions).filter(([key, value]) => {
    return value;
  });

  const renderIcon = () => {
    if (onClick) return <IconChevronRightFill size={16} color={vars.$scale.color.gray600} />;
    if (fold) return <IconChevronDownFill size={20} color={vars.$scale.color.gray600} />;
    return <IconChevronUpFill size={20} color={vars.$scale.color.gray600} />;
  };

  const levelTextTypography = isLarge ? 'caption1Regular' : 'caption2Regular';
  const titleTypography = isLarge ? 'subtitle2Bold' : 'caption1Bold';
  const spacingSize = isLarge ? 16 : 10;

  return (
    <motion.div className={s.Container({ size })}>
      <div className={s.Header}>
        <div className={s.LeftHeader} onClick={handleFold}>
          <GroupLevelProgress
            progress={level.forLevelUp.percentage}
            nextLevel={level.nextLevel}
            size={size}
            currentLevel={level.currentLevel}
          />
          <VerticalSpacing size={spacingSize} />
          <div className={s.HeaderContent}>
            <Typography typography={levelTextTypography} color="gray900" ellipsisAfterLines={1}>
              {levelText}
            </Typography>
            <Spacing size={2} />
            <Typography typography={titleTypography} color="gray900" ellipsisAfterLines={1}>
              {titleText}
            </Typography>
          </div>
        </div>
        <VerticalSpacing size={16} />
        <div onClick={handleFold}>{renderIcon()}</div>
      </div>
      <AnimatePresence initial={false}>
        <motion.div
          key="content"
          initial="open"
          animate={fold ? 'collapsed' : 'open'}
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: 'auto',
            },
            collapsed: {
              opacity: 0,
              height: 0,
            },
          }}
          transition={{
            type: 'just',
          }}
        >
          <Spacing size={16} />
          {levelUpMissions.map(([key, data], index) => {
            const isLast = levelUpMissions.length - 1 === index;
            const missionId = key as keyof GroupLevelUpMissions;
            const missionInfo = MISSION_INFO[missionId];
            if (!missionInfo) return null;
            return (
              <div key={key}>
                <MissionItem
                  missionId={missionId}
                  missionStatus={data}
                  onClickMission={handleMissionClick}
                />
                {!isLast && <Spacing size={8} />}
              </div>
            );
          })}
          <Spacing size={16} />
          <div className={s.MoveToLevelDetail} onClick={handleMoveToLevelDetail}>
            <Typography typography="label3Regular" color="gray600">
              현재 레벨 보기
            </Typography>
            <IconChevronRightFill size={14} color={vars.$scale.color.gray600} />
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default withAsyncBoundary(GroupLevelBanner, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
