import {
  initChallengeTimeAt,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useForm } from 'react-hook-form';

import { useReadNullableGroupDetail } from '@/domain/Group/hooks/useReadNullableGroupDetail';
import GroupChallengeCreateAccessoryBar from '@/features/ChallengeCreate/GroupChallengeCreateAccessoryBar';
import GroupChallengeForm from '@/features/ChallengeCreate/GroupChallengeForm';
import useHandleCreatePermission from '@/features/ChallengeCreate/hooks/useHandleCreatePermission';
import { useTempCreateGroupChallengeForm } from '@/features/ChallengeCreate/hooks/useTempCreateGroupChallengeForm';
import { GroupChallengeBaseForm } from '@/features/ChallengeCreate/types';
import useCheckOpenChallengeCertifyPromotionBottomSheet from '@/features/ChallengePromotion/hooks/useCheckOpenChallengeCertifyPromotionBottomSheet';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';

export const defaultGroupChallengeData: GroupChallengeBaseForm = {
  name: '',
  description: '',
  startedAt: initChallengeTimeAt(),
  totalDays: 28,
  certifyIntervalDays: 7,
  certifyIntervalTargetCount: 7,
  images: [],
  originImages: [],
};

type Params = Pick<ActivityQueryParams, 'groupId' | 'from' | 'defaultValue'>;

const GroupChallengeNewPage: ActivityComponentType<Params> = () => {
  // 모임이 없는 경우에도 챌린지 즉시 생성 플로우가 있어, groupId는 nullable한 상태
  const { defaultValue, from, groupId = undefined } = useQueryParams();

  const defaultData = defaultValue ? JSON.parse(defaultValue) : undefined;
  const formHandler = useForm<GroupChallengeBaseForm>({
    defaultValues: {
      ...defaultGroupChallengeData,
      ...defaultData,
    },
  });

  const { data: group } = useReadNullableGroupDetail(groupId);
  useEnterTrackEvent({
    event: 'enter_challenge_create_page',
    params: {
      groupId,
      groupName: group?.name,
      categoryId: group?.category.id,
      from,
      isCreateChallengeGroup: !groupId,
    },
  });

  useTempCreateGroupChallengeForm({
    groupId,
    formHandler,
  });

  const checkOpenChallengeCertifyPromotionBottomSheet =
    useCheckOpenChallengeCertifyPromotionBottomSheet();

  useDidMountSequentialProcess([checkOpenChallengeCertifyPromotionBottomSheet]);

  useHandleCreatePermission();

  return (
    <AppScreen
      accessoryBar={
        <GroupChallengeCreateAccessoryBar groupId={groupId} formHandler={formHandler} />
      }
    >
      <GroupChallengeForm formHandler={formHandler} />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupChallengeNewPage, {
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
