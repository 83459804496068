import { StoreSlice } from '..';

export type GroupMarketingSlice = {
  is12월덕질MarketingJoinable: boolean;
  is12월덕질MarketingCreate: boolean;
  set12월덕질MarketingJoinable: (value: boolean) => void;
  set12월덕질MarketingCreate: (value: boolean) => void;

  isChallengeCertifyPromotionBottomSheetOpened: boolean;
  setIsChallengeCertifyPromotionBottomSheetOpened: (value: boolean) => void;
};

export const createGroupMarketingSlice: StoreSlice<GroupMarketingSlice> = (set) => ({
  is12월덕질MarketingJoinable: false,
  is12월덕질MarketingCreate: false,
  set12월덕질MarketingJoinable: (value: boolean) => set({ is12월덕질MarketingJoinable: value }),
  set12월덕질MarketingCreate: (value: boolean) => set({ is12월덕질MarketingCreate: value }),
  isChallengeCertifyPromotionBottomSheetOpened: false,
  setIsChallengeCertifyPromotionBottomSheetOpened: (value: boolean) =>
    set({ isChallengeCertifyPromotionBottomSheetOpened: value }),
});
