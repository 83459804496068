import {
  GroupCurrentUserPermissions,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { Typography, useBottomSheet } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';

import CheckAccessiblePermission from '@/components/common/CheckAccessiblePermission';

import { GroupPostFormProps } from './GroupPostForm';
import * as s from './GroupPostFormToolBarBottomSheet.css';

interface Props extends GroupPostFormProps {
  groupId: string;
  role: GroupMemberRoleEnum;
  regionName: string;
  currentUserPermissions: GroupCurrentUserPermissions;
  mode?: 'new' | 'edit';
  isJoinedPhotoContest?: boolean;
}

export const GroupPostFormToolBarBottomSheet = ({
  groupId,
  formHandler,
  mode,
  regionName,
  currentUserPermissions,
  isJoinedPhotoContest,
}: Props) => {
  const { closeAsync } = useBottomSheet();
  const { watch, setValue } = formHandler;

  const [isGroupOnly, setIsGroupOnly] = useState(watch('isGroupOnly'));
  const [isNoticed, setIsNoticed] = useState(watch('isNoticed'));
  const [isPublished, setIsPublished] = useState(watch('isPublished'));
  const [isPhotoContestEntry, setIsPhotoContestEntry] = useState(watch('isPhotoContestEntry'));

  useEffect(() => {
    setValue('isGroupOnly', isGroupOnly);

    if (isGroupOnly === false && mode === 'edit' && isJoinedPhotoContest === true) {
      setIsPhotoContestEntry(true);
      setValue('isPhotoContestEntry', true);
    }
  }, [isGroupOnly, setValue, isJoinedPhotoContest]);

  useEffect(() => {
    setValue('isNoticed', isNoticed);
  }, [isNoticed, setValue]);

  useEffect(() => {
    setValue('isPublished', isPublished);
  }, [isPublished, setValue]);

  useEffect(() => {
    setValue('isPhotoContestEntry', isPhotoContestEntry);
  }, [isPhotoContestEntry, setValue]);

  const exposureRangeInfoText = useMemo(() => {
    if (isGroupOnly) {
      return '모임에 가입한 멤버만 글을 볼 수 있어요.';
    }
    return `모임에 가입하지 않아도 누구나 볼 수 있어요.`;
  }, [isGroupOnly]);

  return (
    <div className={s.Wrapper}>
      <div className={s.Header}>
        <Typography typography="title2Bold">글쓰기 설정</Typography>
        <div style={{ position: 'relative', top: '-4px' }} onClick={closeAsync}>
          <button className={s.CloseButton}>
            <IconXmarkLine size={24} color={vars.$scale.color.gray900} />
          </button>
        </div>
      </div>
      <div
        className={s.ItemWrapper({
          flexDirection: 'column',
        })}
        style={{
          padding: '0.75rem 1rem',
        }}
      >
        <h6 className={s.Title}>공개 범위</h6>
        <p className={s.SubTitle}>{exposureRangeInfoText}</p>
        <Spacing size={18} />
        <div className={s.ToggleWrapper}>
          <div
            className={s.Toggle({
              active: isGroupOnly === false,
            })}
          />
          <p
            className={s.ToggleText({
              active: isGroupOnly === false,
            })}
            onClick={() => {
              setIsGroupOnly(false);
            }}
          >
            누구나
          </p>
          <p
            onClick={() => {
              setIsGroupOnly(true);
              setIsPublished(false);
              setIsPhotoContestEntry(false);
            }}
            className={s.ToggleText({
              active: isGroupOnly === true,
            })}
          >
            멤버만
          </p>
        </div>
      </div>
      <CheckAccessiblePermission
        permission="addNoticePost"
        currentPermission={currentUserPermissions}
      >
        <div
          className={s.ItemWrapper({})}
          style={{
            padding: '0.6563rem 1rem',
          }}
        >
          <h6 className={s.Title}>공지로 등록하기</h6>
          <Switch
            checked={!!isNoticed}
            onClick={() => {
              setIsNoticed(!isNoticed);
            }}
          />
        </div>
      </CheckAccessiblePermission>
      <div
        className={s.ItemWrapper({})}
        style={{
          padding: '0.6563rem 1rem',
        }}
      >
        <div>
          <h6 className={s.Title}>동네생활 탭에 공유하기</h6>
          <p className={s.SubTitle}>{regionName} 근처 모든 이웃들에게 보여요.</p>
        </div>

        <Switch
          disabled={isGroupOnly}
          checked={!!isPublished}
          onClick={() => {
            setIsPublished(!isPublished);
          }}
        />
      </div>
    </div>
  );
};

const Switch = ({
  disabled,
  checked,
  onClick,
}: {
  disabled?: boolean;
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={s.SwitchWrapper({
        disabled,
        checked,
      })}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <motion.div
        className={s.SwitchHandle}
        layout
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 20,
          duration: 100,
        }}
      />
    </div>
  );
};
