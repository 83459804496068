import { GroupChallengeUpdateForm } from '@community-group/api/lib/group/models';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { useQueryChallengeDetail } from '@/domain/Challenge/hooks/useReadChallengeDetail';
import { ImageUploadState } from '@/features/ChallengeCreate/utils/fileUpload';
import GroupChallengeEditAccessoryBar from '@/features/ChallengeEdit/GroupChallengeEditAccessoryBar';
import GroupChallengeEditForm from '@/features/ChallengeEdit/GroupChallengeEditForm';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

type Params = Pick<PageParams, 'groupId' | 'challengeId'>;

export const defaultGroupChallengeEditData = {
  description: '' as string,
  images: [] as string[],
  status: undefined,
  originImages: [] as ImageUploadState[],
};

export type GroupChallengeEditFormProps = GroupChallengeUpdateForm & {
  originImages: ImageUploadState[];
};

const ChallengeEditPage: ActivityComponentType<Params> = () => {
  const { groupId, challengeId } = usePathParams();

  const [{ data: challengeDetail }] = useSuspenseQueries({
    queries: [useQueryChallengeDetail({ groupId, challengeId })],
  });

  const formHandler = useForm<GroupChallengeEditFormProps>({
    defaultValues: {
      ...defaultGroupChallengeEditData,
      description: challengeDetail?.description ?? '',
      status: undefined,
      images: challengeDetail?.images.map((image) => image.id),
      originImages:
        challengeDetail?.images.map((image) => ({
          id: image.id,
          url: image.url,
          status: 'completed',
        })) ?? [],
    },
  });

  return (
    <AppScreen
      appBar={{
        title: '챌린지 수정하기',
      }}
      accessoryBar={<GroupChallengeEditAccessoryBar formHandler={formHandler} />}
    >
      <GroupChallengeEditForm formHandler={formHandler} />
    </AppScreen>
  );
};

export default ChallengeEditPage;
