import { GroupMeetupModifyForm } from '@community-group/api/lib/group/models';
import { BoxButton, Render, withAsyncBoundary } from '@community-group/components';
import { Divider, Typography } from '@community-group/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { usePutGroupMeetup } from '@/api/hooks/usePutGroupMeetup';
import { useBridge } from '@/contexts/Bridge';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { meetupModifyFormSchema } from '@/utils/validate/formSchema/meetup';
import {
  convertHookFormErrorObjectToArray,
  validateSchemaWithBoolean,
} from '@/utils/validate/util';

import { GroupMeetupFormExposureRangeField } from '../../components/Field/ExposureRange';
import { GroupMeetupJoinMemberGradeField } from '../../components/Field/JoinMemberGrade';
import { openListItemFormFieldQuerySelector } from '../../utils/selector';
import * as s from './Options.css';

export type GroupMeetupEditOptionsPageParams = Pick<
  PageParams,
  'groupId' | 'meetupId' | 'meetupModifyForm'
>;

const GroupMeetupEditOptionsPage: ActivityComponentType<GroupMeetupEditOptionsPageParams> = () => {
  const { replace, pop } = useFlow();
  const { bridge } = useBridge();
  const { groupId, meetupId } = usePathParams();
  const { meetupModifyForm } = useQueryParams();

  const hookForm = useForm<GroupMeetupModifyForm>({
    resolver: zodResolver(meetupModifyFormSchema),
  });
  const { handleSubmit: handleFormSubmit } = hookForm;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isPending } = usePutGroupMeetup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_group_meetup_edit',
        params: {
          groupId,
          meetupId,
        },
      });
      refetchGroupDetail({ groupId });
      refetchGroupMeetupDetail({ groupId, meetupId });
    },
  });

  const handleSubmit = () => {
    handleFormSubmit(
      (data) => {
        mutate(
          {
            groupId: parseInt(groupId),
            meetupId: parseInt(meetupId),
            meetupModifyForm: data,
          },
          {
            onSuccess: () => {
              pop(2);
            },
          }
        );
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  useEffect(() => {
    try {
      if (meetupModifyForm) {
        const parsedMeetupModifyForm = JSON.parse(meetupModifyForm);

        if (validateSchemaWithBoolean(meetupModifyFormSchema, parsedMeetupModifyForm)) {
          return hookForm.reset(parsedMeetupModifyForm);
        }
      }

      replace('GroupMeetupEditPage', { groupId, meetupId }, { animate: false });
    } catch {
      replace('GroupMeetupEditPage', { groupId, meetupId }, { animate: false });
    }
  }, [meetupModifyForm]);

  return (
    <AppScreen
      accessoryBar={
        <div className={s.accessoryBarWrapper}>
          <BoxButton
            isLoading={isPending}
            size="xlarge"
            width="100%"
            onClick={handleSubmit}
            isDisabled={isPending}
          >
            수정하기
          </BoxButton>
        </div>
      }
    >
      <form>
        {isEmpty(hookForm.getValues()) ? (
          <></>
        ) : (
          <GroupMeetupEditOptionsView groupId={groupId} hookForm={hookForm} />
        )}
      </form>
    </AppScreen>
  );
};

export default GroupMeetupEditOptionsPage;

type Props = {
  groupId: string;
  hookForm: UseFormReturn<GroupMeetupModifyForm>;
};

const GroupMeetupEditOptionsView = withAsyncBoundary(
  ({ groupId, hookForm: { watch, setValue } }: Props) => {
    const [{ data: me }, { data: memberGradeStatus }] = useSuspenseQueries({
      queries: [useQueryGroupMe({ groupId }), useQueryGroupMemberGradeStatus(groupId)],
    });

    const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
    const memberGradeEnabled = useMemo(() => {
      return (
        Boolean(memberGradeEnabledFeatureFlag) &&
        memberGradeStatus === 'system' &&
        me.grade.name !== '준회원'
      );
    }, [memberGradeEnabledFeatureFlag, memberGradeStatus, me]);

    useEffect(() => {
      openListItemFormFieldQuerySelector(memberGradeEnabled ? 'canJoinAnyMember' : 'exposureRange');
    }, [memberGradeEnabled]);

    return (
      <div className={s.wrapper}>
        <Typography as="h2" typography="title1Bold" color="gray900" style={{ padding: '1rem 0' }}>
          옵션을 선택해주세요
        </Typography>
        <Render condition={memberGradeEnabled}>
          <GroupMeetupJoinMemberGradeField
            name="canJoinAnyMember"
            watch={watch}
            setValue={setValue}
          />
          <Divider />
        </Render>
        <GroupMeetupFormExposureRangeField name="exposureRange" watch={watch} setValue={setValue} />
        <Divider />
      </div>
    );
  },
  {}
);
