import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  HorizontalSpacing,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { IconPersonShieldFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import * as stackflow from '@/stackflow';

import * as s from './style.css';
type Props = {
  groupId: string;
  currentUser: GroupCurrentUser;
  groupDetail: GroupDetailPresentation;
};
const GroupInfoSection = ({ groupId, currentUser, groupDetail }: Props) => {
  const push = stackflow?.useFlow().push;
  const needVerifiedGroup = useMemo(() => {
    return groupDetail?.needVerification;
  }, [groupDetail?.needVerification]);

  const verifiedInfoText = useMemo(() => {
    if (!needVerifiedGroup) return '';

    let renderText = '본인인증 모임';

    if (groupDetail?.canViewVerifiedUserInfo) renderText += ' ∙ 성별 및 나이 공개';

    return renderText;
  }, [groupDetail?.canViewVerifiedUserInfo, needVerifiedGroup]);
  return (
    <div className={s.SectionWrapper}>
      <img
        className={s.GroupProfileImage}
        src={groupDetail.profileImage.medium}
        alt="모임 프로필 이미지"
      />
      <div className={s.GroupInfo}>
        <Typography typography="title3Bold" color="gray900" className={s.TextWrapper}>
          {groupDetail.name}
        </Typography>
        <HorizontalSpacing size={2} />
        <Typography typography="caption1Regular" color="gray600" className={s.TextWrapper}>
          {groupDetail.groupCreatedRegion.name}
          {' ∙ '}
          {dayjs(groupDetail.createdAt).format('YYYY.MM.DD')} 개설
        </Typography>
        <HorizontalSpacing size={8} />
        {needVerifiedGroup && (
          <div className={s.TextBox}>
            <IconPersonShieldFill size={14} color={vars.$scale.color.gray600} />
            <Typography typography="label3Regular" color="gray700">
              {verifiedInfoText}
            </Typography>
          </div>
        )}
      </div>
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <div className={s.ButtonContainer}>
          <BoxButton
            width="100%"
            variant="secondary"
            size="medium"
            onClick={() => {
              push('GroupDetailPreviewPage', { groupId });
            }}
          >
            미리보기
          </BoxButton>
          <VerticalSpacing size={8} />
          <BoxButton
            width="100%"
            variant="secondary"
            size="medium"
            onClick={() => {
              push('GroupSettingEditGroupInfoPage', { groupId });
            }}
          >
            모임 정보 수정
          </BoxButton>
        </div>
      </CheckAccessibleRole>
    </div>
  );
};

export default GroupInfoSection;
