import { MainProfile } from '@community-group/api/lib/group/models';
import { Render, Typography } from '@community-group/components';
import { useMemo } from 'react';

import { ProfileMeetupMemberReviewMessageItem } from '@/components/group/profile/MemberProfileDetail/components/MeetupMemberReviewMessageItem';
import ReceivedReviewsSection from '@/components/group/profile/MemberProfileDetail/components/RecievedReviewsSection';

type Props = {
  mainProfile?: MainProfile;
};
const GroupMainProfileReviewSection = ({ mainProfile }: Props) => {
  const isMyProfile = !!mainProfile?.isMyProfile;

  const currentMeetupMemberReviewMessages = useMemo(
    () => mainProfile?.meetupMemberReviewMessages ?? [],
    [mainProfile]
  );

  const filteredMeetupMemberReviewMessages = useMemo(() => {
    if (isMyProfile) return currentMeetupMemberReviewMessages;

    return currentMeetupMemberReviewMessages.filter(
      ({ reviewExposureRange }) => reviewExposureRange === 'ALL'
    );
  }, [currentMeetupMemberReviewMessages, isMyProfile]);

  if (!mainProfile) return null;

  return (
    <section style={{ paddingBottom: '7.5rem' }}>
      {mainProfile?.meetupMemberReviews.length > 0 && (
        <ReceivedReviewsSection reviews={mainProfile?.meetupMemberReviews} />
      )}
      <Render condition={filteredMeetupMemberReviewMessages.length > 0}>
        <ul style={{ paddingTop: '1rem' }}>
          <div style={{ padding: '0 1rem', marginBottom: '1rem' }}>
            <Typography typography="title3Bold">받은 감사 인사</Typography>
          </div>
          {filteredMeetupMemberReviewMessages.map((memberReviewMessage) => (
            <ProfileMeetupMemberReviewMessageItem
              key={memberReviewMessage.id}
              isMyProfile={isMyProfile}
              meetupMemberReviewMessage={memberReviewMessage}
            />
          ))}
        </ul>
      </Render>
    </section>
  );
};

export default GroupMainProfileReviewSection;
