import { model } from '@community-group/api/lib/group';
import { Dialog, useDialog, useSnackbarAdapter } from '@community-group/components';

import { usePatchApplicationAcceptance } from '@/api/hooks/usePatchApplicationAcceptance';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useConnectedFlow } from '@/stackflow';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import { MemberRejectMenuBottomSheetRequest } from '../pages/BottomSheet/MemberRejectMenuBottomSheet';
import { GroupMemberRejectMessagePageRequest } from '../pages/MemberRejectMessage';

interface Props {
  userId: number;
  groupId: string;
  currentUserRole?: model.GroupMemberRoleEnum;
  onPatchSuccess?: () => void;
  onAccept?: () => void;
  onReject?: () => void;
}

export const useHandleApplication = ({
  userId,
  groupId,
  currentUserRole,
  onPatchSuccess,
  onAccept,
  onReject,
}: Props) => {
  const snackbarAdapter = useSnackbarAdapter();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutateAsync: patchApplication } = usePatchApplicationAcceptance({
    onError: handleErrorWithToast,
    onSuccess: onPatchSuccess,
  });
  const { mutate: mutateJoinableRegionRange } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupMemberList({ groupId });
      refetchGroupDetail({ groupId });
    },
  });

  const acceptApplication = async () => {
    await patchApplication({
      id: Number(groupId),
      userId,
      form: {
        status: 'accept',
      },
    });
    snackbarAdapter.create({
      message: '가입을 승인했어요. 새로 온 이웃에게 참여를 요청해보세요.',
      type: 'default',
      timeout: 3000,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
    onAccept?.();
  };

  const handleAccept = () => {
    acceptApplication();
  };

  const rejectApplication = async (comment?: string) => {
    await patchApplication({
      id: Number(groupId),
      userId,
      form: {
        status: 'reject',
        comment,
      },
    });
    snackbarAdapter.create({
      message: '가입을 거절했어요.',
      type: 'default',
      timeout: 3000,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
    onReject?.();
  };

  const { push } = useConnectedFlow();
  const rejectWithMessage = async () => {
    const request = (await push('GroupMemberRejectMessagePage', {
      groupId,
    })) as unknown as GroupMemberRejectMessagePageRequest;

    switch (request.method) {
      case 'reject':
        rejectApplication(request.message);
        break;
      case 'close':
      default:
        return;
    }
  };

  const handleReject = async () => {
    const request = (await push('BottomSheet/MemberRejectMenuBottomSheet', {
      groupId,
    })) as unknown as MemberRejectMenuBottomSheetRequest;

    switch (request) {
      case 'reject':
        rejectApplication();
        break;
      case 'rejectWithMessage':
        await rejectWithMessage();
        break;
    }
  };

  return {
    handleAccept,
    handleReject,
  };
};
