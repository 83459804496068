import { GroupAvatar, Typography, withAsyncBoundary } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { PropsWithChildren } from 'react';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { isNotMember } from '@/utils/role';

import * as s from './GroupMeetupDetailCommentListInputButton.css';

type Props = PropsWithChildren<{
  groupId: string;
  meetupId: string;
  totalCommentCount: number;
}>;

const GroupMeetupDetailCommentListInputButton = ({
  groupId,
  meetupId,
  totalCommentCount,
  children,
}: Props) => {
  const { data: groupMe } = useReadGroupMe({ groupId });
  const { data: group } = useReadGroupDetail(groupId);
  const { handleGroupOnly } = useHandleGroupOnly({ groupId });

  const { push } = useFlow();
  const handleClick = () => {
    trackEvent({
      event: 'click_group_meetup_comment_more',
      params: {
        role: groupMe.role,
      },
      sample: true,
    });

    const moveToCommentListPage = () => {
      push('CommentListPage', {
        groupId,
        relatedId: meetupId,
        relatedContentType: 'meetup',
        commentCount: totalCommentCount.toString(),
        groupName: group?.name,
      });
    };

    handleGroupOnly({
      isBlock: isNotMember(groupMe.role),
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getComment,
      onSettled: moveToCommentListPage,
      onSuccess: moveToCommentListPage,
    });
  };

  return (
    <div className={s.Container} onClick={handleClick}>
      <GroupAvatar src={groupMe.profileImage} size="small" />
      <VerticalSpacing size={8} />
      <button className={s.InputButton}>
        <Typography typography="label3Regular" color="gray600">
          {children}
        </Typography>
      </button>
    </div>
  );
};

export default withAsyncBoundary(GroupMeetupDetailCommentListInputButton, {});
