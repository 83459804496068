import { AsyncBoundary, BoxButton, ViewError, ViewLoader } from '@community-group/components';
import { IconPlusLine, IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useInView } from 'react-intersection-observer';

import IconWrapper from '@/components/common/Icons';
import ImpressionTriggeredComponents from '@/components/common/Impression/ImpressionTriggeredComponents';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import ChallengeList from '@/features/ChallengeList/ChallengeList';
import EmptyChallengeList from '@/features/ChallengeList/EmptyChallengeList';
import useChallengeListData from '@/features/ChallengeList/hooks/useChallengeListData';
import useCheckOpenChallengeCertifyPromotionBottomSheet from '@/features/ChallengePromotion/hooks/useCheckOpenChallengeCertifyPromotionBottomSheet';
import ChallengeListPromotionBanner from '@/features/ChallengePromotion/PromotionBanner/ChallengeListPromotionBanner';
import useChallengeCertifyPromotion from '@/hooks/useChallengeCertifyPromotion';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';

import * as s from './style.css';

const GroupChallengeListPage: ActivityComponentType = () => {
  const { groupId } = usePathParams();

  const { push } = useFlow();
  const enableChallengePromotion = useChallengeCertifyPromotion('enableChallengePromotion');

  const { data: group } = useReadGroupDetail(groupId);
  useEnterTrackEvent({
    event: 'enter_challenge_list_page',
    params: {
      groupId,
      groupName: group?.name,
      categoryId: group?.category.id,
    },
  });

  const handleCreateChallenge = () => {
    trackEvent({
      event: 'click_challenge_list_create_button',
      params: {
        groupId,
        groupName: group?.name,
        categoryId: group?.category.id,
      },
    });

    push('GroupChallengeNewTemplatePage', {
      groupId: groupId.toString(),
      from: 'shortcut',
    });
  };

  const handleOpenSurvey = () => {
    const surveyLink = 'https://www.daangn.com/wv/faqs/16948';
    openLink(surveyLink, false);
  };

  const checkOpenChallengeCertifyPromotionBottomSheet =
    useCheckOpenChallengeCertifyPromotionBottomSheet();

  useDidMountSequentialProcess([checkOpenChallengeCertifyPromotionBottomSheet]);

  const { ref, inView } = useInView({
    root: null,
    threshold: 1,
    triggerOnce: false,
  });

  return (
    <AppScreen
      appBar={{
        title: '모임 챌린지',
        renderRight: () => {
          return (
            <IconWrapper>
              <IconQuestionmarkCircleLine size={24} onClick={handleOpenSurvey} />
            </IconWrapper>
          );
        },
        border: !inView,
        borderColor: vars.$semantic.color.divider1,
        borderSize: '1px',
      }}
      onPull={() => {
        refetchGroupChallenge({ groupId });
      }}
      disabledClientOnly
    >
      <div className={s.Container}>
        <ImpressionTriggeredComponents ref={ref} />
        <BoxButton
          variant="secondary"
          prefix={<IconPlusLine size={16} />}
          onClick={handleCreateChallenge}
        >
          챌린지 만들기
        </BoxButton>
        {enableChallengePromotion && (
          <ChallengeListPromotionBanner style={{ marginTop: '.75rem' }} />
        )}
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <ChallengeListCore
            groupId={Number(groupId)}
            groupName={group?.name}
            groupCategoryId={group?.category.id}
          />
        </AsyncBoundary>
      </div>
    </AppScreen>
  );
};

const ChallengeListCore = ({
  groupId,
  groupName,
  groupCategoryId,
}: {
  groupId: number;
  groupName?: string;
  groupCategoryId?: number;
}) => {
  const { opened, prepared, closed, hasChallengeData } = useChallengeListData({
    groupId: Number(groupId),
  });
  const enableChallengePromotion = useChallengeCertifyPromotion('enableChallengePromotion');

  return (
    <>
      {!hasChallengeData && <EmptyChallengeList withSurvey={enableChallengePromotion} />}
      {hasChallengeData && (
        <>
          <ChallengeList
            groupId={Number(groupId)}
            groupName={groupName}
            groupCategoryId={groupCategoryId}
            title="진행 중인 챌린지"
            {...opened}
          />
          <ChallengeList
            groupId={Number(groupId)}
            groupName={groupName}
            groupCategoryId={groupCategoryId}
            title="진행 예정 챌린지"
            {...prepared}
          />
          <ChallengeList
            groupId={Number(groupId)}
            groupName={groupName}
            groupCategoryId={groupCategoryId}
            title="종료된 챌린지"
            {...closed}
          />
        </>
      )}
    </>
  );
};

export default GroupChallengeListPage;
