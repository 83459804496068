import {
  GroupCurrentUser,
  GroupCurrentUserGroupInfoStateApprovalConditions,
  GroupCurrentUserGroupInfoStateJoinApprovalRequireReason,
  GroupDetailPresentationJoinType,
  GroupMemberStateEnum,
} from '@community-group/api/lib/group/models';
import { isMember } from '@community-group/components';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';

export type JoinGroupStateProps = {
  groupId: string;
};

export type JoinGroupStateReturnType = {
  groupInfo: {
    id: string;
    name: string;
    joinType?: GroupDetailPresentationJoinType;
    regionName: string;
    categoryName: string;
    isSettingSubNickname: boolean;
    needVerification: boolean;
    canViewVerifiedUserInfo: boolean;
    watched: boolean;
    isShowChatRoomSetting: boolean;
    isDeactivateChatRoom: boolean;
    description: string;
  };
  currentUser: GroupCurrentUser;
  userState: GroupMemberStateEnum; // 유저 모임 가입 상태 (대기, 거절, 미가입, 탈퇴 등)
  isGroupMember: boolean; // 모임 멤버인지 여부 (멤버가 아니라면 위의 userState로 자세한 이유를 알 수 있다.)
  joinApprovalRequireReason: GroupCurrentUserGroupInfoStateJoinApprovalRequireReason;
  // 유저가 모임 가입할 때 필요한 조건 (무조건 가입 가능, 승인 하에 가입 가능, 승인도 받아야 가능)
  approvalConditions: GroupCurrentUserGroupInfoStateApprovalConditions; // 승인 하에 가입을 해야한다면 (질문이 필수인지, 아닌지 / 승인이 필요 없는지)
  approvalQuestion: string; // 질문이 있다면 질문은 무엇인지?
};

export const useJoinGroupState = ({ groupId }: JoinGroupStateProps): JoinGroupStateReturnType => {
  const [{ data: group }, { data: groupMe }] = useSuspenseQueries({
    queries: [useQueryGroupDetail(groupId), useQueryGroupMe({ groupId })],
  });

  const getJoinApprovalRequireReason = () => {
    return groupMe.groupInfoState.joinApprovalRequireReason ?? 'none';
  };

  return {
    groupInfo: {
      id: groupId,
      name: group?.name ?? '',
      joinType: group?.joinType,
      regionName: group?.groupCreatedRegion.name ?? '',
      categoryName: group?.category.name ?? '',
      isSettingSubNickname: group?.subNicknameSetting?.isSettingOn ?? false,
      needVerification: group?.needVerification ?? false,
      canViewVerifiedUserInfo: group?.canViewVerifiedUserInfo ?? false,
      watched: group?.watched ?? false,
      isShowChatRoomSetting: group?.chatRoomSetting.isShowChatRoomSetting ?? false,
      isDeactivateChatRoom: group?.chatRoomSetting.isDeactivate ?? false,
      description: group?.description ?? '',
    },
    currentUser: groupMe,
    userState: groupMe.state ?? 'notMember',
    isGroupMember: isMember(groupMe.role),
    joinApprovalRequireReason: getJoinApprovalRequireReason(),
    approvalConditions: groupMe.groupInfoState.approvalConditions ?? 'none',
    approvalQuestion: group?.applicationConfig?.questions[0]?.question ?? '',
  };
};
