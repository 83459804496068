import { BoxButton, useKeyboardState, useSnackbarAdapter } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { usePostChallenge } from '@/domain/Challenge/hooks/usePostChallenge';
import { useReadNullableGroupDetail } from '@/domain/Group/hooks/useReadNullableGroupDetail';
import useCheckGroupProfileCreated from '@/hooks/useCheckGroupProfileCreated';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';

import { GroupChallengeBaseForm } from '../types';
import { validateCreateDate, validateCreateDescription, validateCreateName } from '../utils/date';
import * as s from './style.css';

type Props = {
  groupId?: string;
  formHandler: UseFormReturn<GroupChallengeBaseForm>;
};

const GroupChallengeCreateAccessoryBar = ({ groupId, formHandler }: Props) => {
  const snackbarAdapter = useSnackbarAdapter();
  const { replace, push } = useFlow();

  const { opened: isOpenedKeyboard } = useKeyboardState();

  const { state: profileState } = useCheckGroupProfileCreated({});

  const isSubmitReady = useMemo(() => {
    const formData = formHandler.watch();

    const originImages = formData.originImages ?? [];

    if (originImages.find((image) => image.status !== 'completed')) return false;

    return true;
  }, [formHandler]);

  const { data: group } = useReadNullableGroupDetail(groupId);
  const handleErrorWithToast = useHandleErrorWithToast();

  // TODO: 신규 API, 서버 배포 후 동작시켜야함 - 피쳐플래그 분기 됨
  const { mutate, isPending } = usePostChallenge({
    onSuccess: ({ data }) => {
      if (!data.challengeId) return;
      const formData = formHandler.getValues();

      if (!groupId) {
        const eventParams = {
          groupId: data.groupId,
          challengeId: data.challengeId,
          groupName: formData.name,
          description: formData.description,
          needVerification: false,
          isSettingOnSubnickname: false,
          challengeDuration: formData.certifyIntervalDays,
          challengeFrequency: formData.certifyIntervalTargetCount,
          challengeDescription: formData.description.slice(0, 50),
          hasExampleImage: formData.originImages.length > 0,
          isCreateChallengeGroup: !groupId,
        };

        trackEvent({
          event: 'click_finish_create_challenge',
          params: eventParams,
        });
      }

      trackEvent({
        event: 'click_finish_create_challenge',
        params: {
          challengeId: data.challengeId.toString(),
          challengeName: formData.name,
          challengeDuration: formData.certifyIntervalDays,
          challengeFrequency: formData.certifyIntervalTargetCount,
          challengeDescription: formData.description.slice(0, 50),
          hasExampleImage: formData.originImages.length > 0,
          groupId,
          groupName: group?.name,
          categoryId: group?.category.id,
          isCreateChallengeGroup: !groupId,
        },
      });

      refetchGroupChallenge({
        groupId: data.groupId.toString(),
        challengeId: data.challengeId.toString(),
      });

      replace('GroupChallengeDetailPage', {
        groupId: data.groupId.toString(),
        challengeId: data.challengeId.toString(),
        from: !groupId ? 'instantCreateChallenge' : undefined,
      });
    },
    onError: handleErrorWithToast,
  });

  const validateCreateChallenge = ({
    name,
    description,
    startedAt,
    originImages,
  }: GroupChallengeBaseForm) => {
    const nameValidation = validateCreateName({ name });
    if (!nameValidation.isValid) return nameValidation;

    const descriptionValidation = validateCreateDescription({ description });
    if (!descriptionValidation.isValid) return descriptionValidation;

    const isTodayAndOver22 = validateCreateDate({ startedAt });
    if (!isTodayAndOver22.isValid) return isTodayAndOver22;

    const allImagesUploaded = originImages.every((image) => image.status === 'completed');
    if (!allImagesUploaded)
      return {
        // TODO: 실패 메시지 다듬기
        message: '업로드에 실패한 이미지가 있어요. 수정 후 다시 시도해주세요.',
        isValid: false,
      };

    return { message: '', isValid: true };
  };

  const disabledButton = useMemo(() => {
    const title = formHandler.watch('name');
    const description = formHandler.watch('description');
    const images = formHandler.watch('originImages');
    const allImagesUploaded = images.every((image) => image.status === 'completed');

    if (!allImagesUploaded) return true;
    if ((!title || title.length === 0) && (!description || description.length === 0)) return true;

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formHandler.watch('name'),
    formHandler.watch('description'),
    formHandler.watch('originImages'),
  ]);

  const handleSubmit = () => {
    const currentForm = formHandler.getValues();
    const { originImages, ...form } = currentForm;

    const { isValid, message } = validateCreateChallenge(currentForm);

    if (!isValid) {
      return snackbarAdapter.create({
        message,
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
    }

    const groupChallengeCreateForm = {
      ...form,
      images: originImages.map((image) => image.id),
    };

    const groupChallengeCreateRequest = {
      groupId: groupId ? Number(groupId) : undefined,
      challenge: groupChallengeCreateForm,
    };

    // 메인 프로필이 없는 경우 프로필 생성 페이지로 이동
    if (profileState.needToProfileSet) {
      push('GroupProfileCreatePage', {
        groupId,
        createChallengeForm: JSON.stringify(groupChallengeCreateRequest),
        from: 'GroupChallengeNewPage',
      });
      return;
    }

    // groupId가 없는 경우에는 모임 생성과 함께 챌린지 생성되어져요.
    mutate({
      groupChallengeCreateRequest,
    });
  };

  const CTAText = useMemo(() => {
    if (groupId) return '챌린지 만들기';
    return '챌린지 모임 만들기';
  }, [groupId]);

  return (
    <div
      className={s.Wrapper}
      style={{
        paddingBottom: isOpenedKeyboard
          ? `calc(0.5rem)`
          : `calc(env(safe-area-inset-bottom) + .5rem)`,
        borderTop: `1px solid ${vars.$semantic.color.divider1}`,
      }}
    >
      <BoxButton
        size="xlarge"
        width="100%"
        isDisabled={isPending || !isSubmitReady || disabledButton}
        isLoading={isPending}
        onClick={handleSubmit}
      >
        {CTAText}
      </BoxButton>
    </div>
  );
};

export default GroupChallengeCreateAccessoryBar;
