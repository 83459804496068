import {
  AsyncBoundary,
  BottomBasicButton,
  MultilineTextField,
  useStickInputStore,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { useEffect, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetEventLogMyInfo } from '@/api/hooks/useGetEventLogMyInfo';
import { useGetFeedList } from '@/api/hooks/useGetFeedList';
import { useReadNullableGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePostApplication } from '@/api/hooks/usePostApplication';
import { usePostGroupProfile } from '@/api/hooks/usePostGroupProfile';
import { usePostJoinGroup } from '@/api/hooks/usePostJoinGroup';
import { usePutGroupMeetupJoin } from '@/api/hooks/usePutGroupMeetupJoin';
import { FormInput } from '@/components/common/base/Input/Text';
import { FormContainer, FormTitle } from '@/components/common/Form';
import { useJoinGroupState } from '@/components/group/JoinGroupState/hooks/useJoinGroupState';
import useGroup12월덕질MarketingPromotion from '@/components/group/MarketingPromotion/24.12/hooks/useGroup12월덕질MarketingPromotion';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import {
  LayoutRefProps,
  LayoutWrapper,
} from '@/stackflow/components/Layout/components/ContentsLayout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { GroupEventParams } from '@/utils/analytics/type';
import { openLink } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';

import { GroupCreateProfileHeader } from './GroupCreateProfileHeader';
import { GroupCreateProfileImageUploader } from './GroupProfileImageUploader';

const GroupCreateProfileAndJoinGroupPage = () => {
  const { answer, instantJoinMeetupId } = useQueryParams();
  const { groupId = '' } = useQueryParams();
  const { setOpenWebviewModal, setRecommendGroup } = useStore();
  const { userConfig } = useUserConfig();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { setFocused } = useStickInputStore();

  const { data: group } = useReadGroupDetail(groupId);
  const { data: currentUser } = useReadGroupMe({ groupId });

  const { refetch: refetchFeedList } = useGetFeedList(groupId);
  const { data: groupMeetupData } = useReadNullableGroupMeetupDetail({
    groupId: groupId?.toString() ?? '',
    meetupId: instantJoinMeetupId?.toString(),
  });
  const { groupInfo, joinApprovalRequireReason, approvalConditions } = useJoinGroupState({
    groupId,
  });

  const { register, watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      name: currentUser.nickname || '',
    },
  });

  const nameFieldValue = watch('name');
  const descriptionFieldValue = watch('description');

  const { push, pop } = useFlow();
  const handleBack = useBackToActivity();

  const hasQuestion =
    approvalConditions === 'optional_answer' || approvalConditions === 'require_answer';

  const {
    profileImage,
    handleSetGroupProfile,
    isLoading: isProfileImageLoading,
  } = useHandleSetGroupProfile({});

  const { mutate: mutateCreateGroupProfile } = usePostGroupProfile({
    onError: (error) => {
      handleErrorWithToast(error);
    },
    onSuccess: () => {
      trackCreateGroupProfileEvent();
      refetchGroupProfile();
      // 일정 신청과 동시에 모임을 가입할 때
      if (instantJoinMeetupId) {
        // 승인 후 가입일 경우 승인 페이지에서 가입
        if (approvalConditions === 'optional_answer' || approvalConditions === 'require_answer') {
          push('GroupProfileApprovalPage', {
            groupId: groupId,
            instantJoinMeetupId,
          });
          return;
        }

        mutateGroupMeetupJoin({
          groupId: groupId,
          meetupId: instantJoinMeetupId,
          groupMeetupJoinForm: {
            answer: answer
              ? {
                  questionId: group?.applicationConfig?.questions[0].id || 0,
                  answer: String(answer),
                }
              : undefined,
          },
        });
      }

      // 가입 승인이 필요 없는 경우
      if (joinApprovalRequireReason === 'none') {
        joinMutate({
          id: Number(groupId),
          joinForm: {},
        });
        return;
      }

      // 가입 승인이 필요한 경우 (and 질문 있음) -> 질문 답변 페이지로 이동
      if (approvalConditions === 'optional_answer' || approvalConditions === 'require_answer') {
        push('GroupProfileApprovalPage', {
          groupId: groupId,
        });
        return;
      }

      // 가입 승인이 필요한 경우 (and 질문 없음)
      applicationMutate({
        id: Number(groupId),
        groupMemberApplicationForm: {
          answers: answer
            ? [
                {
                  questionId: group?.applicationConfig?.questions[0].id || 0,
                  answer: String(answer),
                },
              ]
            : [],
        },
      });
      pop();
    },
  });
  const { bridge } = useBridge();

  const { mutate: mutateGroupMeetupJoin, isPending: isSubmitMeetupJoinLoading } =
    usePutGroupMeetupJoin({
      onError: (error) => handleErrorWithToast(error),
      onSuccess: async () => {
        trackJoinGroupEvent();

        if (
          approvalConditions === 'none' ||
          approvalConditions === 'optional_answer' ||
          approvalConditions === 'require_answer'
        ) {
          bridge.openToast({
            toast: {
              body: '참여를 신청했어요. 승인되면 다시 알려드릴게요.',
            },
          });
        }

        refetchGroupMeetupDetail({
          groupId,
          meetupId: instantJoinMeetupId?.toString() ?? '',
        });

        refetchGroupDetail({
          groupId,
        });
        trackJoinGroupMeetupEvent();

        if (joinApprovalRequireReason === 'none') {
          openLink(groupMeetupData?.channelInfo.targetUri);

          handleBack({ activityName: 'GroupMeetupDetailPage' });
          return;
        }

        handleBack({ activityName: 'HomePage' });
      },
    });

  const trackCreateGroupProfileEvent = () => {
    trackEvent({
      event: 'click_integrated_group_profile_complete',
      params: {
        meetupId: instantJoinMeetupId,
        groupId: groupId.toString() ?? '',
        userRole: currentUser.role,
        category: group?.category.name,
        currentUserState: currentUser.state,
        isInstantMeetupJoin: true,
        joinApprovalRequireReason,
      },
      sample: true,
    });
  };

  const trackJoinGroupMeetupEvent = () => {
    trackEvent({
      event: 'click_join_group_meetup',
      params: {
        meetupId: instantJoinMeetupId,
        groupId: groupId.toString() ?? '',
        userRole: currentUser.role,
        currentMeetupJoinStatus: groupMeetupData?.currentUserInfo.meetupJoinStatus,
        category: group?.category.name,
        currentUserState: currentUser.state,
        isInstantMeetupJoin: true,
        joinApprovalRequireReason,
      },
      loggerType: extendAppsflyerLoggerType,
    });
  };

  const { data: eventMyInfo } = useGetEventLogMyInfo();

  const trackJoinGroupEvent = () => {
    const isFirstJoinGroup = !eventMyInfo?.data.isJoinedGroup;

    const eventParams: GroupEventParams = {
      joinType: group?.joinType,
      groupId: group?.id,
      groupName: group?.name,
      groupCategoryName: group?.category.name,
      checkinReigionId: userConfig.regionId ?? 0,
      checkinMostAccurate: userConfig.regionTownName ?? '',
      isInstantMeetupJoin: !!instantJoinMeetupId,
      joinApprovalRequireReason,
      needVerification: group?.needVerification,
      canViewVerifiedUserInfo: group?.canViewVerifiedUserInfo,
      watched: group?.watched,
      isFirstJoinGroup,
      isChatRequired: !groupInfo.isShowChatRoomSetting,
      isChatActivated: !groupInfo.isDeactivateChatRoom,
    };

    trackEvent({
      event: 'click_join_group',
      params: eventParams,
      loggerType: extendAppsflyerLoggerType,
    });

    if (isFirstJoinGroup) {
      trackEvent({
        event: 'click_first_join_group',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });
    }
  };

  const handleErrorWithToast = useHandleErrorWithToast();

  //TODO: 12월 덕질 프로모션 가입 본 마케팅 프로모션, 종료 후 제거 가능
  const { handleOpen12월덕질MarketingPromotionBottomSheet } = useGroup12월덕질MarketingPromotion();

  const { mutate: applicationMutate, isPending: isSubmitApplicationLoading } = usePostApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async (data) => {
      trackJoinGroupEvent();

      if (data.data.application.recommendedGroups) {
        await setRecommendGroup(data.data.application.recommendedGroups);
        setOpenWebviewModal(MODAL_KEY.RECOMMEND_3_OTHER_GROUPS, true);
      }
      refetchGroupDetail({ groupId });
      refetchFeedList();

      if (hasQuestion) {
        pop();
      }

      handleOpen12월덕질MarketingPromotionBottomSheet({
        groupId,
        type: 'apply',
      });
    },
  });

  const { mutate: joinMutate, isPending: isSubmitJoinLoading } = usePostJoinGroup({
    groupId,
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      trackJoinGroupEvent();

      refetchGroupDetail({ groupId });
      refetchFeedList();

      pop();
      handleOpen12월덕질MarketingPromotionBottomSheet({
        groupId,
        type: 'join',
      });
    },
  });

  const handleSubmit = () => {
    mutateCreateGroupProfile({
      groupMainProfileCreateForm: {
        nickname: nameFieldValue,
        description: descriptionFieldValue,
        profileImageId: profileImage?.id,
      },
    });
  };

  const ref = useRef<LayoutRefProps>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setViweportInput({
        wrapperRef,
        callbackHandler: [
          {
            elementId: 'description-textarea',
            callback: (e) => {
              const el = e.target as HTMLTextAreaElement;
              el?.parentElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            },
          },
        ],
      });
    }
  }, []);

  const isSubmitLoading =
    isSubmitApplicationLoading ||
    isSubmitJoinLoading ||
    isSubmitMeetupJoinLoading ||
    isProfileImageLoading;

  const renderAccessoryBarButton = () => {
    if (instantJoinMeetupId) {
      return (
        <BottomBasicButton
          disabled={!nameFieldValue || isSubmitLoading}
          loading={isSubmitLoading}
          onClick={handleSubmit}
        >
          완료
        </BottomBasicButton>
      );
    }
    return (
      <BottomBasicButton
        disabled={!nameFieldValue || isSubmitLoading}
        loading={isSubmitLoading}
        onClick={handleSubmit}
      >
        {joinApprovalRequireReason === 'none' ? '가입하기' : '가입 신청'}
      </BottomBasicButton>
    );
  };

  return (
    <AppScreen layoutRef={ref} accessoryBar={renderAccessoryBarButton()}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <LayoutWrapper ref={wrapperRef} padding="0 1rem 1rem 1rem">
          <GroupCreateProfileHeader />
          <Spacing size={24} />
          <GroupCreateProfileImageUploader
            profileImage={profileImage}
            handleSetGroupProfile={handleSetGroupProfile}
            isLoading={isProfileImageLoading}
          />
          <Spacing size={24} />

          <FormContainer>
            <FormTitle>닉네임</FormTitle>
            <FormInput
              register={register}
              name="name"
              placeholder="닉네임을 입력해주세요."
              maxLength={12}
              value={nameFieldValue}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <Spacing size={24} />
            <FormTitle>자기소개</FormTitle>

            <div id="description-textarea">
              <MultilineTextField
                value={descriptionFieldValue ?? ''}
                onChange={(value) => {
                  setValue('description', value);
                }}
                placeholder="자기소개를 입력해주세요."
                maxLength={200}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </div>
          </FormContainer>
        </LayoutWrapper>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupCreateProfileAndJoinGroupPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
