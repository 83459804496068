import { Skeleton } from '@community-group/components';
import { Spacing } from '@community-group/components';

import * as s from './GroupDetailSkeleton.css';

type Props = {
  isExistHeroSlider?: boolean;
};

const GroupDetailSkeleton = ({ isExistHeroSlider = true }: Props) => (
  <div>
    {isExistHeroSlider && (
      <Skeleton
        height="100%"
        style={{
          height: '100%',
          aspectRatio: '1 / 1',
          maxHeight: '400px',
        }}
      />
    )}
    <div className={s.HeaderWrapper}>
      <div className={s.ProfileWrapper}>
        <Skeleton width="3rem" height="3rem" borderRadius=".75rem" />
      </div>
      <div className={s.MemberListWrapper}>
        <Skeleton enableAnimation width="5rem" height="1.5rem" borderRadius="0.3rem" />
        <Spacing size={4} />
        <Skeleton enableAnimation width="12rem" height="1.25rem" borderRadius="0.3rem" />
      </div>
    </div>
  </div>
);

export default GroupDetailSkeleton;
