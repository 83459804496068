import { ChallengeCertifyPointsResponse } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { openPayHome } from '@karrotpay/account-sdk';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo } from 'react';

import { useBridge } from '@/contexts/Bridge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import GuideCallout from '@/features/ChallengePromotion/GuideCallout';
import BonusCallout from '@/features/ChallengePromotion/GuideCallout/BonusCallout';
import { CHALLENGE_PROMOTION_LANDKIT_LINK } from '@/features/ChallengePromotion/PromotionBanner/ChallengeListPromotionBanner';
import TransparentNavBar from '@/features/ChallengePromotion/TransparentNavBar';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { openLink } from '@/utils/link';

import * as s from './style.css';

type Params = Pick<PageParams, 'groupId' | 'postId' | 'challengeCertifyPromotionResult' | 'point'>;

const COIN_DROP_PNG =
  'https://asset-town.krrt.io/production/motion/b43d9ec8-7397-4235-91d3-1dc9b423fe5f/eaa375194e90fd0ecba8c9738775cf18c975ede1.png';

const BONUS_ASSET = {
  30: 'https://assetstorage.krrt.io/1025317940251365954/578a039c-41a4-4fb7-a9c9-0078c449c5e0/width=207,height=207.webp',
  20: 'https://assetstorage.krrt.io/1025317940251365954/21527fe0-5c8a-4807-98c9-85ad47d31d95/width=207,height=207.webp',
  10: 'https://assetstorage.krrt.io/1025317940251365954/4138e4a3-4571-443a-9f10-c4fba2a18bfc/width=207,height=207.webp',
  2: 'https://assetstorage.krrt.io/1025317940251365954/c056fcb0-40a7-4223-aa25-04ac8c5a6bb0/width=207,height=207.webp',
};

const ChallengeCertifyPointResultPage: ActivityComponentType<Params> = () => {
  const { challengeCertifyPromotionResult, point } = useQueryParams();
  const handleBack = useBack();
  const { groupId, postId } = usePathParams();
  const { bridge } = useBridge();
  const { data: group } = useReadGroupDetail(groupId);

  const parsedChallengeCertifyPromotionResult = JSON.parse(
    challengeCertifyPromotionResult ?? '{}'
  ) as ChallengeCertifyPointsResponse;

  const hasData = Object.keys(parsedChallengeCertifyPromotionResult).length !== 0;

  const hasBonus = parsedChallengeCertifyPromotionResult.bonus;

  useEffect(() => {
    if (!hasData) {
      handleBack();
    }
  }, [hasData, handleBack]);

  useEnterTrackEvent({
    event: 'enter_challenge_certify_result_page',
    params: {
      ...parsedChallengeCertifyPromotionResult,
      point,
      postId,
      groupId,
      groupName: group?.name,
      groupCategoryName: group?.category?.name,
    },
  });

  const titleText = useMemo(() => {
    const hasNoGuide = !parsedChallengeCertifyPromotionResult.guide;
    return (
      <>
        {parsedChallengeCertifyPromotionResult?.dateDisplayText} 인증 완료
        <br />
        {hasBonus && hasNoGuide ? '당근머니를 더 많이 받았어요!' : '당근머니를 받았어요!'}
      </>
    );
  }, [parsedChallengeCertifyPromotionResult, hasBonus]);

  if (!parsedChallengeCertifyPromotionResult) {
    return (
      <AppScreen>
        <ViewError />
      </AppScreen>
    );
  }

  return (
    <AppScreen hasAppBar={false}>
      <div className={s.Container}>
        <TransparentNavBar />
        <div className={s.MainContainer}>
          <div className={s.ContentsContainer}>
            <div className={s.Tooltip}>
              <Typography typography="label4Regular" color="primary">
                2025 새해목표 달성 챌린지
              </Typography>
            </div>
            <Spacing size={16} />
            <Typography typography="title2Bold" color="gray900" className={s.Title}>
              {titleText}
            </Typography>
            <Spacing size={8} />
            <Typography typography="h1" color="gray900" className={s.Title}>
              {Number(point).toLocaleString()}원
            </Typography>
            <div className={s.CoinDropImageContainer}>
              <img
                src={COIN_DROP_PNG}
                alt="챌린지 포인트 지급 이미지"
                className={s.CoinDropImage}
              />
              {hasBonus && parsedChallengeCertifyPromotionResult.bonus && (
                <div className={s.BonusTextContainer}>
                  <img
                    src={BONUS_ASSET[parsedChallengeCertifyPromotionResult.bonus]}
                    className={s.BonusImgAsset}
                  />
                </div>
              )}
            </div>
            <Spacing size={24} />
            <Typography
              typography="caption1Regular"
              color="gray600"
              className={s.ShowPointaListButton}
              onClick={() => {
                openPayHome({
                  stage: globalThis.appConfig.appEnv,
                  bridge: bridge as any,
                  params: { from: 'community_group_challenge_promotion_2501' },
                });
              }}
            >
              받은 내역 보기
            </Typography>
          </div>
          <div className={s.BottomSection}>
            {parsedChallengeCertifyPromotionResult.guide ? (
              <GuideCallout guide={parsedChallengeCertifyPromotionResult.guide} />
            ) : hasBonus ? (
              <BonusCallout />
            ) : null}
            <div className={s.Button} onClick={handleBack}>
              <BoxButton variant="primary" size="xlarge" width="100%">
                확인
              </BoxButton>
            </div>
            <Typography
              typography="label3Bold"
              color="gray900"
              className={s.MoreInfoButton}
              onClick={() => {
                openLink(CHALLENGE_PROMOTION_LANDKIT_LINK, true);
              }}
            >
              이벤트 자세히 보기
            </Typography>
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(ChallengeCertifyPointResultPage, {
  rejectedFallback: (
    <AppScreen hasAppBar={false}>
      <ViewError />
    </AppScreen>
  ),
  pendingFallback: (
    <AppScreen hasAppBar={false}>
      <ViewLoader />
    </AppScreen>
  ),
});
