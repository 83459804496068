import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import NotificationSettingSection from '../components/SettingSection/Notification';

const GroupSettingNotification: ActivityComponentType = () => {
  const { groupId } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);

  return (
    <AppScreen appBar={{ title: group?.name ?? '' }}>
      <NotificationSettingSection isSection={false} />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingNotification, {
  rejectedFallback: <ViewError />,
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
