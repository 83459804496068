export const GROUP_MEMBER_QUERY_KEY = {
  base: (groupId: string) => ['groupMember', groupId] as const,
  memberGrades: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGrades'] as const,
  memberRoles: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberRoles'] as const,
  memberGradeEditSections: (groupId: string, userId: number) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGradeEditSections', userId] as const,
  memberGradeStatus: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGradeStatus'] as const,
  members: (groupId: string) => [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'members'] as const,
  hostMembers: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'hostMembers'] as const,
  memberGradeRequirements: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGradeRequirements'] as const,
  memberGradeApplicationStatus: (groupId: string, userId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGradeApplicationStatus', userId] as const,
  memberGradeApplications: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGradeApplications'] as const,
  memberGradeApplicationCount: (groupId: string) =>
    [...GROUP_MEMBER_QUERY_KEY.base(groupId), 'memberGradeApplicationCount'] as const,
  memberGradeApplicationDecision: (groupId: string, gradeApplicationId: string) =>
    [
      ...GROUP_MEMBER_QUERY_KEY.base(groupId),
      'memberGradeApplicationDecision',
      gradeApplicationId,
    ] as const,
};
