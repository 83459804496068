import { GroupMeetupDetailPresentation } from '@community-group/api/lib/group/models';
import { useSnackbarAdapter } from '@community-group/components';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useDeleteGroupMeetupLike } from '@/api/hooks/useDeleteGroupMeetupLike';
import { getGroupMeetupDetailPath } from '@/api/hooks/useGetGroupMeetupDetail';
import { queryClient } from '@/shared/api/instance';
import { getMyInfoGroupMeetupLikedPageLink, openHref } from '@/utils/link';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { refetchMyInfoLikedMeetupList } from '@/utils/refetch/myInfoLikedMeetupList';

import { usePutGroupMeetupLike } from './../api/hooks/usePutGroupMeetupLike';

type Props = {
  groupId: string;
  meetupId: string;
};

type MutateProps = UseMutationOptions<AxiosResponse, Error, Props>;

export const useToggleGroupMeetupLike = ({ groupId, meetupId }: Props) => {
  const snackbar = useSnackbarAdapter();
  const { mutate: putGroupMeetupLikeMutate } = usePutGroupMeetupLike({
    onError: () => {
      updateLikeData(false, { groupId, meetupId });
    },
    onSuccess: () => {
      snackbar.create({
        message: '관심 일정에 추가했어요.',
        actionLabel: '목록 보기',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbar.dismiss();
        },
        onAction: () => {
          const targetUri = getMyInfoGroupMeetupLikedPageLink();
          setTimeout(() => {
            openHref(targetUri);
          }, 100);
        },
      });
    },
    onSettled: () => {
      refetchMyInfoLikedMeetupList();
      setTimeout(() => {
        refetchGroupMeetupDetail({ groupId, meetupId });
      }, 500);
    },
  });

  const { mutate: deleteGroupMeetupLikeMutate } = useDeleteGroupMeetupLike({
    onError: () => {
      updateLikeData(true, { groupId, meetupId });
    },
    onSettled: () => {
      refetchMyInfoLikedMeetupList();
      setTimeout(() => {
        refetchGroupMeetupDetail({ groupId, meetupId });
      }, 500);
    },
  });

  return (like: boolean, options?: MutateProps) => {
    if (like) {
      deleteGroupMeetupLikeMutate({ groupId, meetupId }, options);
    } else {
      putGroupMeetupLikeMutate({ groupId, meetupId }, options);
    }

    updateLikeData(!like, { groupId, meetupId });
  };
};

const updateLikeData = (currentLike: boolean, { groupId, meetupId }: Props) => {
  queryClient.setQueryData<GroupMeetupDetailPresentation>(
    [getGroupMeetupDetailPath(groupId, meetupId)],
    (prevData) => {
      if (!prevData) return;
      return {
        ...prevData,
        currentUserInfo: {
          ...prevData.currentUserInfo,
          like: currentLike,
        },
      };
    }
  );
};
