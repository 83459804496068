import {
  AsyncBoundary,
  TextButton,
  useBottomSheet,
  validateUploadingVideos,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType, useStack } from '@stackflow/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { GROUP_URL } from '@/api/base/post';
import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { getFeedListQueryKey } from '@/api/hooks/useGetFeedList';
import { usePostArticle } from '@/api/hooks/usePostArticle';
import { useBridge } from '@/contexts/Bridge';
import { useQueryNullableChallengeDetail } from '@/domain/Challenge/hooks/useReadNullableChallengeDetail';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupLevel } from '@/domain/GroupLevel/hooks/useReadGroupLevel';
import { GROUP_PROFILE_QUERY_KEY } from '@/domain/GroupProfile/queries';
import useActiveActivities from '@/hooks/useActiveActivities';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useOpenDialogValidateFormChallengeCertify } from '@/hooks/useOpenDialogValidateFormChallengeCertify';
import { useStorage } from '@/hooks/useStorage';
import { queryClient } from '@/shared/api/instance';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupRunningGrowth } from '@/utils/refetch/runningGrowth';
import { postFormSchema } from '@/utils/validate/formSchema/post';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

import { getCurrentRunningGrowthStep } from '../../Detail/components/RunningGrowth/utils/curerntLevel';
import { PollFormActivityParamsJSON } from '../../Poll/pages';
import { GroupPostFormAppBar } from '../components/AppBar';
import AppBarTitle from '../components/AppBar/AppBarTitle';
import { GroupPostForm, GroupPostFormHandlerProps } from '../components/GroupPostForm';
import { GroupPostNewStartBottomSheet } from '../components/GroupPostNewStartBottomSheet';
import { typeSafeBoolean } from '../utils';
import * as s from './PostForm.css';

export type GroupPostNewPageParams = Pick<
  PageParams,
  'groupId' | 'postType' | 'content' | 'meetupId' | 'initialPublishPost' | 'challengeId'
>;

const GroupPostNewPage: ActivityComponentType = () => {
  const { groupId = '' } = usePathParams();
  const {
    content = '',
    postType: queryParamsPostType = 'post',
    meetupId: queryParamsMeetupId,
    challengeId: queryParamsChallengeId,
    initialPublishPost,
    pollOptionValueJSONString,
    from,
    calloutType,
  } = useQueryParams();

  const { isExistSpecificActivities } = useActiveActivities();

  const [{ data: group }, { data: challenge }, { data: myInfo }, { data: levelData }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupDetail(groupId),
        useQueryNullableChallengeDetail({ groupId, challengeId: queryParamsChallengeId }),
        useQueryGroupMe({ groupId }),
        useQueryGroupLevel(groupId),
      ],
    });

  const { bridge } = useBridge();
  const initialPollOption = pollOptionValueJSONString
    ? (JSON.parse(pollOptionValueJSONString) as PollFormActivityParamsJSON)
    : undefined;

  const { pop, replace, push } = useConnectedActions();
  const backPop = useBack();
  const { data: boardCateogries } = useGetBoardTypeList(Number(groupId));

  const [isPostFormIsGroupOnly, setIsPostFormIsGroupOnly] = useStorage(
    'postFormIsGroupOnly',
    false
  );

  const enableMyChallengeResultPage = useFeatureFlag('enableMyChallengeResultPage');

  const mentionSuggestionsRef = useRef<HTMLDivElement>(null);

  const pollOptionTitles = () => {
    if (!initialPollOption) return undefined;

    const options = initialPollOption.pollOptionTitles.filter(
      (pollOption) => !isNil(pollOption)
    ) as string[];

    return options.map((option) => ({
      title: option,
    }));
  };

  const isGroupPhotoContestFunnel = useMemo(() => from === 'groupPhotoContest', [from]);
  const formHandler = useForm({
    defaultValues: {
      isGroupOnly: isPostFormIsGroupOnly ?? false,
      content: decodeURIComponent(content),
      images: [],
      originImages: [],
      prevImages: [],
      isPublished: initialPublishPost ? true : false,
      isNoticed: false,
      mentionedUserIds: [],
      poiStreams: [],
      postType: {
        type: queryParamsPostType,
        meetupId: queryParamsMeetupId ? Number(queryParamsMeetupId) : 0,
        challengeId: queryParamsChallengeId ? Number(queryParamsChallengeId) : undefined,
      },
      isPhotoContestEntry: isGroupPhotoContestFunnel,
      boardCategoryIds: [
        boardCateogries?.boardCategories?.find((item) => item.type === 'default')?.id,
      ],
      poll: initialPollOption
        ? {
            options: pollOptionTitles(),
            title: initialPollOption.title,
            isMultiple: initialPollOption.isMultiple,
          }
        : undefined,
      uploadVideos: [],
    } as GroupPostFormHandlerProps,
  });
  const { watch, getValues, setValue } = formHandler;

  const { open: openBottomSheet } = useBottomSheet();
  const handleBack = useBackToActivity();

  const handleGroupPostBottomSheet = () => {
    openBottomSheet({
      element: (
        <GroupPostNewStartBottomSheet
          groupId={groupId}
          formHandler={formHandler}
          hasMention={false}
          replacePage={replace}
          popPage={pop}
          pushPage={push}
        />
      ),
      config: {
        dimOpacity: 0,
      },
    });
  };

  const { setOpenWebviewModal } = useStore();

  useEffect(() => {
    if (queryParamsPostType === 'meetupReview' || queryParamsPostType === 'challengeCertify') {
      return;
    }

    if (from === 'groupPhotoContest') {
      setValue('isGroupOnly', false);
      return;
    }

    if (from === 'HeroImage') {
      return;
    }

    if (
      group?.isBoardManaged &&
      boardCateogries?.boardCategories &&
      boardCateogries?.boardCategories.length > 1
    ) {
      setOpenWebviewModal(MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET, true);
      return;
    }

    handleGroupPostBottomSheet();
  }, []);

  useEnterTrackEvent({
    event: 'enter_group_write_post',
    params: {
      groupId,
      queryParamsPostType,
      calloutType,
      currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
      currentLevel: levelData?.currentLevel,
      currentProgressPercentage: levelData?.forLevelUp.percentage,
      userId: (myInfo.id ?? 0).toString(),
      categoryId: group?.category.id,
      categoryName: group?.category.name,
      from,
    },
    sample: true,
    loggerType: ['APPSFLYER', 'AMPLITUDE', 'KARROT'],
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const {
    mutate: postArticle,
    isPending,
    status,
  } = usePostArticle({
    onError: handleErrorWithToast,
    onSuccess: (data) => {
      refetchGroupDetail({ groupId });
      queryClient.refetchQueries({
        queryKey: [`${GROUP_URL}/${groupId}/notices`],
      });

      // 채팅방에서 일정 생성시 창 닫기
      if (from === 'chat') {
        bridge.closeRouter({});
        return;
      }

      if (group?.isBoardManaged) {
        queryClient.refetchQueries({
          queryKey: [getFeedListQueryKey(groupId ?? ''), data.post.boardCategories[0].id],
        });
      }

      queryClient.refetchQueries({
        queryKey: [`${GROUP_URL}/${groupId}/notices`],
      });

      queryClient.refetchQueries({
        queryKey: GROUP_PROFILE_QUERY_KEY.activities(groupId, myInfo.id.toString()),
      });

      refetchGroupRunningGrowth({ groupId: Number(groupId) });

      // 챌린지 인증 글인 경우 챌린지 상세 페이지로 이동되지 않도록 하기 위해 push를 사용
      // * 글 작성 완료 후 스택
      // - 일반글 : 모임 홈 > 작성된 게시글 상세
      // - 챌린지 인증글 : 모임 홈 > 챌린지 인증 결과 페이지
      if (
        enableMyChallengeResultPage &&
        queryParamsPostType === 'challengeCertify' &&
        data.post.challengeInfo?.id
      ) {
        const move = isExistSpecificActivities('GroupDetailPage') ? push : replace;

        handleBack({
          activityName: 'GroupDetailPage',
          isCloseRouterActivityNotExist: false,
        });

        move('ChallengeMyDailyCertifyResultPage', {
          groupId,
          postId: data.post.id,
          challengeId: data.post.challengeInfo.id,
        });
        return;
      }

      replace(
        'GroupPostDetailPage',
        {
          groupId,
          postId: data.post.id,
        },
        {
          animate: true,
        }
      );
    },
  });

  const handlePostSubmit = () => {
    const watchData = watch();

    const data = {
      ...watchData,
      isNoticed: typeSafeBoolean(watchData?.isNoticed),
      isGroupOnly: typeSafeBoolean(watchData?.isGroupOnly),
      isPublished: typeSafeBoolean(watchData?.isPublished),
    };

    // todo: ts-ignore 제거 (참고: https://github.com/daangn/community-web-group/pull/698#issue-1641248802)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const images = (data.images ?? []).map(({ id }) => id);

    const mediasVideo = (data.uploadVideos ?? [])
      .filter(({ uploadedVideoInfo }) => uploadedVideoInfo?.id)
      .map(({ uploadedVideoInfo }) => {
        return {
          id: uploadedVideoInfo?.id,
          type: 'video',
        };
      });

    const mediasImage = images
      .filter((id) => !id.includes('temp') && !id.includes('error'))
      .map((id) => {
        return {
          id,
          type: 'image',
        };
      });

    const invalidUploadVideo = validateUploadingVideos(data.uploadVideos ?? []);
    if (invalidUploadVideo) {
      bridge.openToast({
        toast: {
          body: invalidUploadVideo.message,
        },
      });
      return false;
    }

    if (
      !validateSchemaWithHandleToast(postFormSchema, {
        content: data.content,
        images,
        medias: [...mediasVideo, ...mediasImage],
        postType: data.postType,
      })
    ) {
      return false;
    }

    // TODO: 전국러닝그로스 미션 끝나고 제거 가능
    if (calloutType === 'running-growth-mission2-set-notice') {
      if (data.isNoticed === false) {
        bridge.openToast({
          toast: {
            body: '미션을 완료하려면, 게시글을 공지로 등록해주세요.',
          },
        });
        return;
      }
    }
    if (calloutType === 'running-growth-mission3-set-running-course') {
      if (images.length < 2) {
        bridge.openToast({
          toast: {
            body: '미션을 완료하려면, 게시글에 러닝코스를 표시한 사진을 2장 이상 첨부해주세요.',
          },
        });
        return;
      }
    }

    postArticle(
      {
        groupId,
        ...data,
        poiItems: [...(data.poiStreams?.map(({ poiId, type }) => ({ id: poiId, type })) ?? [])],
        images,
        medias: [...mediasVideo, ...mediasImage],
      },
      {
        onSuccess: () => {
          const isSelectedDefaultBoardCategory =
            boardCateogries?.boardCategories?.find((item) => item.id === data.boardCategoryIds?.[0])
              ?.type === 'default';

          setIsPostFormIsGroupOnly(data.isGroupOnly);

          refetchGroupRunningGrowth({ groupId: Number(groupId) });

          // 챌린지 인증글인 경우 데이터 Refetch
          if (data.postType?.type.includes('challenge')) {
            refetchGroupChallenge({
              groupId,
              challengeId: data.postType?.challengeId?.toString() ?? '',
            });
          }

          trackEvent({
            event: 'click_write_post',
            params: {
              groupId,
              isSelectedDefaultBoardCategory,
              isNoticed: data.isNoticed,
              isGroupOnly: data.isGroupOnly,
              isPublished: data.isPublished,
              isMentionedUser: (data.mentionedUserIds ?? []).length > 0,
              hasImages: (data.images ?? []).length > 0,
              hasPois: (data.poiStreams ?? []).length > 0,
              hasVideos: (data.uploadVideos ?? []).length > 0,
              videoCount: (data.uploadVideos ?? []).length,
              postType: data.postType?.type,
              hasPoll: !!data.poll,
              pollName: data.poll?.title,
              pollOptionCount: data.poll?.options.length,
              pollMultipleOption: data.poll?.isMultiple,
              role: myInfo.role,
              referrer: from,
              calloutType,
              currentRunningGrowthMission: getCurrentRunningGrowthStep(calloutType),
              from,
              photoEvent: data.isPhotoContestEntry,
              currentLevel: levelData?.currentLevel,
              currentProgressPercentage: levelData?.forLevelUp.percentage,
              userId: (myInfo.id ?? 0).toString(),
              categoryId: group?.category.id,
              categoryName: group?.category.name,
              challengeId: queryParamsChallengeId,
              challengeName: challenge?.name,
            },
            sample: true,
            loggerType: extendAppsflyerLoggerType,
          });
        },
      }
    );
  };

  const openDialogValidateFormChallengeCertify = useOpenDialogValidateFormChallengeCertify();

  const handleSubmitClick = () => {
    if (isPending || status === 'success') return;

    const hasImages = (getValues('images') ?? []).length > 0;

    // 챌린지 인증 글 validation check
    // post type이 challengeCertify 인데, 사진이 없는 경우 다이얼로그 띄어주기
    if (queryParamsPostType === 'challengeCertify' && !hasImages) {
      return openDialogValidateFormChallengeCertify({
        onPostAnyway: () => {
          // 이대로 올리기 한 경우 postType을 초기값으로 변경
          setValue('postType.type', 'post');
          handlePostSubmit();
        },
      });
    }

    handlePostSubmit();
  };

  const isValidSubmit = () => {
    const watchData = watch();

    if (watchData.content.length <= 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (watch('originImages').length > 0) {
        return false;
      }
      if (watch('uploadVideos').length > 0) {
        return false;
      }
      return true;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (watch('originImages').some(({ id }) => id.includes('temp') || id.includes('error'))) {
      return true;
    }

    if (watch('uploadVideos').some(({ status }) => status === 'failed' || status === 'uploading')) {
      return true;
    }

    return false;
  };

  const textareaParentRef = useRef<HTMLDivElement>(null);

  const handleFocusTextarea = (cursorEnd = true) => {
    setTimeout(() => {
      const textarea: HTMLInputElement | null | undefined =
        textareaParentRef?.current?.querySelector('[data-name="MentionsInput"]');

      const end = textarea?.textContent?.length;

      if (cursorEnd) {
        textarea?.setSelectionRange(end as number | null, end as number | null);
      }

      textarea?.focus();
    });
  };
  const handleBlurTextarea = () => {
    const textarea: HTMLInputElement | null | undefined = textareaParentRef?.current?.querySelector(
      '[data-name="MentionsInput"]'
    );
    textarea?.blur();
  };

  const placeholder = useMemo(() => {
    if (queryParamsPostType === 'challengeCertify') {
      return '사진과 함께 오늘의 인증 내용을 입력해주세요.';
    }
    return '질문이나 이야기를 남겨보세요';
  }, [queryParamsPostType]);

  return (
    <AppScreen
      appBar={{
        title: <AppBarTitle group={group} />,
        backButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={backPop}>
              <IconXmarkLine />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={backPop}>
              <IconXmarkLine />
            </div>
          ),
        },
        renderRight: () => (
          <>
            <TextButton
              onClick={handleSubmitClick}
              variant="secondary"
              isDisabled={isValidSubmit() || isPending || status === 'success'}
              UNSAFE_style={{ padding: '0.5rem' }}
            >
              완료
            </TextButton>
          </>
        ),
      }}
      accessoryBar={
        <GroupPostFormAppBar
          mode="new"
          handleBlurTextarea={handleBlurTextarea}
          handleFocusTextarea={handleFocusTextarea}
          formHandler={formHandler}
          handleAddButton={handleGroupPostBottomSheet}
          mentionSuggestionsRef={mentionSuggestionsRef}
        />
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupPostForm
          mode="new"
          formHandler={formHandler}
          handleAddButton={handleGroupPostBottomSheet}
          textareaParentRef={textareaParentRef}
          mentionSuggestionsRef={mentionSuggestionsRef}
          placeholder={placeholder}
        />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupPostNewPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
