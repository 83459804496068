import { useBottomSheet } from '@community-group/components';
import { IconChevronDownLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { HTMLAttributes } from 'react';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { useUserConfig } from '@/contexts/UserConfig';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { convertNicknameDisplay } from '@/utils/nickname';

import { GroupPostFormProps } from './GroupPostForm';
import { GroupPostFormToolBarBottomSheet } from './GroupPostFormToolBarBottomSheet';
import SelectBoardTypeBottomSheet from './SelectBoardTypeBottomSheet';
import * as s from './Toolbar.css';

export const GroupPostFormToolBar = ({
  formHandler,
  mode,
  handleAddButton,
  isJoinedPhotoContest,
}: GroupPostFormProps) => {
  const { groupId = '' } = usePathParams();

  const { data } = useReadGroupMe({ groupId });
  const { nickname, profileImage, role, subNickname } = data ?? {
    nickname: '',
    profileImage: '',
    role: 'member',
  };

  const { data: boardCategoires } = useGetBoardTypeList(Number(groupId));
  const { data: group } = useReadGroupDetail(groupId);
  const currentUser = useCurrentGroupMe();

  const { open: openBottomSheet } = useBottomSheet();

  const { watch, setValue } = formHandler;

  const boardCategoryIds = watch('boardCategoryIds')?.[0] || 0;
  const { setOpenWebviewModal, openWebviewModal } = useStore();
  const { userConfig } = useUserConfig();

  const handleIsGroupOnlyClick = () => {
    openBottomSheet({
      element: (
        <GroupPostFormToolBarBottomSheet
          groupId={groupId}
          regionName={userConfig.regionTownName ?? ''}
          role={role}
          mode={mode}
          formHandler={formHandler}
          currentUserPermissions={currentUser?.permissions}
          isJoinedPhotoContest={isJoinedPhotoContest}
        />
      ),
    });
  };

  const handleOnClickBoardType = () => {
    if (group?.isBoardManaged) {
      setOpenWebviewModal(MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET, true);
      return;
    }

    handleAddButton?.();
  };

  return (
    <div className={s.Wrapper}>
      <img
        className={s.Profile}
        src={profileImage}
        width={36}
        height={36}
        loading="eager"
        alt={`${nickname}의 프로필`}
      />
      <div className={s.ContentsWrapper}>
        <p className={s.Nickname}>
          {convertNicknameDisplay({
            nickname,
            subNickname: subNickname,
          })}
        </p>
        <div className={s.LabelWrapper}>
          <div>
            <Label onClick={handleIsGroupOnlyClick}>
              {watch('isGroupOnly') ? '멤버만 공개' : '전체 공개'}
            </Label>
          </div>
          {watch('postType.type') === 'meetupReview' && <Label>일정 후기</Label>}
          {group?.isBoardManaged && (
            <>
              <Label onClick={handleOnClickBoardType}>
                {boardCategoryIds === 0
                  ? boardCategoires?.boardCategories?.find((item) => item.type === 'default')
                      ?.category
                  : boardCategoires?.boardCategories?.find((item) => item.id === boardCategoryIds)
                      ?.category}
              </Label>
            </>
          )}

          {watch('isNoticed') && <Label>공지</Label>}
          {watch('isPublished') && <Label>동네생활에 공유</Label>}
        </div>
      </div>
      {openWebviewModal.selectBoardTypeBottomSheet && (
        <SelectBoardTypeBottomSheet
          setSelectedCategory={(value) => {
            setValue('boardCategoryIds', [value]);
            setOpenWebviewModal(MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET, false);
            handleAddButton?.();
          }}
          selectedCategory={boardCategoryIds}
          groupId={groupId}
        />
      )}
    </div>
  );
};

type LabelProps = HTMLAttributes<HTMLLabelElement>;

const Label = ({ children, onClick, ...props }: LabelProps) => {
  const handleClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    onClick?.(event);
  };

  return (
    <label className={s.LabelItem} {...props} onClick={handleClick}>
      {children}
      {onClick && <IconChevronDownLine size={14} color={vars.$scale.color.gray700} />}
    </label>
  );
};
