import { VerticalSpacing } from '@community-group/components';
import { SettingList } from '@community-group/components/features';

import IconNewBadge from '@/assets/images/icon_new_badge.svg';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useReadGroupPermissions } from '@/domain/GroupPermission/hooks/useReadGroupPermissions';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupManagement.css';

const GroupManagementSettingSection = () => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const { data: me } = useReadGroupMe({ groupId });
  const { data: permissions } = useReadGroupPermissions(groupId);
  const memberGradeEnabled = useFeatureFlag('memberGradeEnabled');

  return (
    <SettingList.Wrapper title="모임 운영">
      <SettingList.Item
        title="멤버 관리"
        onClick={() => {
          push('GroupMemberListForHostPage', {
            groupId,
          });
        }}
      />
      <CheckAccessibleRole currentRole={me.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="멤버 권한 관리"
          onClick={() => {
            push('GroupSettingPermissionPage', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      {memberGradeEnabled && (
        <CheckAccessibleRole currentRole={me.role} accessibleRoles={['superHost']}>
          <SettingList.Item
            title={
              <div className={s.SettingTitleContainer}>
                <span>멤버 등급 관리</span>
                <VerticalSpacing size={4} />
                <img src={IconNewBadge} alt="new" />
              </div>
            }
            description="멤버 등급을 나누고 관리해요."
            onClick={() => {
              push('GroupSettingMemberGradePage', {
                groupId,
                from: 'setting',
              });
            }}
          />
        </CheckAccessibleRole>
      )}
      <CheckAccessibleRole currentRole={me.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="게시판 관리"
          onClick={() => {
            trackEvent({
              event: `click_board_category_setting`,
              params: {
                groupId,
              },
            });
            push('BoardCategorySetting', {
              groupId: groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      <CheckAccessibleRole currentRole={me.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="별명"
          description="닉네임 옆에 모임에서만 사용하는 별명을 표시해요."
          onClick={() => {
            push('GroupSettingEditSubNicknamePage', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      <CheckAccessibleRole currentRole={me.role} accessibleRoles={['superHost']}>
        <SettingList.Item
          title="본인인증 설정"
          description="멤버의 본인인증, 성별과 나이대 공개 여부를 설정해요."
          onClick={() => {
            push('GroupSettingEditVerification', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      <CheckAccessibleRole
        currentRole={me.role}
        accessibleRoles={permissions.modifyGroupBotSetting.currentRoles}
      >
        <SettingList.Item
          title="모임장봇 설정"
          onClick={() => {
            push('GroupSettingGroupBotPage', {
              groupId,
              from: 'setting',
            });
          }}
        />
      </CheckAccessibleRole>
    </SettingList.Wrapper>
  );
};

export default GroupManagementSettingSection;
