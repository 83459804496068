import { useMemo } from 'react';

import { FeatureFlagKeysType, useFeatureFlag } from './useFeatureFlag';

type FeatureFlagType = keyof FeatureFlagKeysType;

const useChallengeCertifyPromotion = <T extends FeatureFlagType>(
  featureFlagName: T
): FeatureFlagKeysType[T] | false => {
  const flag = useFeatureFlag(featureFlagName);

  const isAfterEndTime = useMemo(() => {
    const endTime = new Date('2025-02-03T14:59:59Z');
    const now = new Date();
    return now > endTime;
  }, []);

  return flag && !isAfterEndTime;
};

export default useChallengeCertifyPromotion;
