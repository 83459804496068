import { AvoidSnackbarOverlap, withAsyncBoundary } from '@community-group/components';
import { RefObject } from 'react';

import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';

import useMeetupCommentSubmit from '../hooks/useMeetupCommentSubmit';
import CommentFormAccessoryBarCore from './CommentFormAccessoryBarCore';

type Props = {
  listRef: RefObject<HTMLDivElement>;
  groupId: string;
  relatedId: string;
  parentCommentId?: string;
  placeholder?: string;
};

const MeetupCommentFormAccessoryBar = ({
  listRef,
  groupId,
  relatedId,
  parentCommentId,
  placeholder,
}: Props) => {
  const { data: currentUser } = useReadGroupMe({ groupId });
  const handleSubmit = useMeetupCommentSubmit({
    listRef,
    groupId,
    meetupId: relatedId,
    parentCommentId,
    userId: currentUser.id.toString(),
  });

  return (
    <AvoidSnackbarOverlap>
      <CommentFormAccessoryBarCore
        groupId={groupId}
        onSubmit={handleSubmit}
        placeholder={placeholder}
      />
    </AvoidSnackbarOverlap>
  );
};

export default withAsyncBoundary(MeetupCommentFormAccessoryBar, {});
