import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Dialog,
  HelpBubbleTrigger,
  Typography,
  useDialog,
  useSnackbarAdapter,
} from '@community-group/components';
import { useActivity } from '@stackflow/react';
import { useQueries } from '@tanstack/react-query';
import { differenceInDays } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';

import { useDeleteApplication } from '@/api/hooks/useDeleteApplication';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useBridge } from '@/contexts/Bridge';
import { usePostJoinChallengeParticipants } from '@/domain/Challenge/hooks/usePostJoinChallengeParticipants';
import { useQueryChallengeMyCertifyActivity } from '@/domain/Challenge/hooks/useReadChallengeMyCertifyActivity';
import { useQueryGroupDetail, useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useChallengeCertifyButtonClick } from '@/features/ChallengePromotion/hooks/useChallengeCertifyButtonClick';
import useChallengeCertifyPromotion from '@/hooks/useChallengeCertifyPromotion';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { isNotMember } from '@/utils/role';

import * as s from './style.css';
type Props = {
  challengeDetail: ChallengeDetailPresentation;
};

const ChallengeAccessoryBar = ({ challengeDetail }: Props) => {
  const { groupId, challengeId } = usePathParams();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { bridge } = useBridge();
  const { isTop } = useActivity();

  const enableChallengePromotion = useChallengeCertifyPromotion('enableChallengePromotion');

  // 승인후 가입 > 질문 답변 후 userState를 업데이트 해하기 위한 훅
  useEffect(() => {
    if (isTop) {
      refetchGroupMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTop]);

  const { data: groupMe, refetch: refetchGroupMe } = useReadGroupMe({ groupId });
  const snackbar = useSnackbarAdapter();
  const isJoined = challengeDetail.isJoined;

  const { clickCount, incrementClickCount } = useChallengeCertifyButtonClick(challengeId);

  const isOpenPromotionTooltip = useMemo(() => {
    return enableChallengePromotion && !challengeDetail.todayCertified && clickCount < 3;
  }, [enableChallengePromotion, challengeDetail, clickCount]);

  const { push } = useFlow();
  const [{ data: group }, { data: myCertifyActivity }] = useQueries({
    queries: [
      useQueryGroupDetail(groupId),
      useQueryChallengeMyCertifyActivity({
        groupId: Number(groupId),
        challengeId: Number(challengeId),
      }),
    ],
  });
  const { handleGroupOnly } = useHandleGroupOnly({ groupId });

  const { mutate: deleteApplicationMutate } = useDeleteApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      refetchGroupMe();
      bridge.openToast({
        toast: {
          body: '가입 신청을 취소했어요.',
        },
      });
    },
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateJoinChallengeParticipants } = usePostJoinChallengeParticipants({
    onSuccess: () => {
      trackEvent({
        event: 'click_success_attend_challenge',
        params: {
          groupId,
          challengeId,
          challengeName: challengeDetail.name,
          categoryId: group?.category.id,
          groupName: group?.name,
          hasExampleImage: challengeDetail.images?.length ?? 0 > 0,
        },
      });
      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId });
    },
    onError: handleErrorWithToast,
  });

  const handleWriteCertificationPost = useCallback(() => {
    incrementClickCount();
    trackEvent({
      event: 'click_challenge_certify_button',
      params: {
        challengeId,
        challengeName: challengeDetail.name,
        challengeDuration: challengeDetail.certifyIntervalDays,
        challengeFrequency: challengeDetail.certifyIntervalTargetCount,
        groupId,
        hasExampleImage: challengeDetail.images?.length ?? 0 > 0,
        groupName: group?.name,
        categoryId: group?.category.id,
      },
    });
    push('GroupPostNewPage', {
      groupId,
      challengeId,
      postType: 'challengeCertify',
    });
  }, [
    challengeDetail.certifyIntervalDays,
    challengeDetail.certifyIntervalTargetCount,
    challengeDetail.images?.length,
    challengeDetail.name,
    challengeId,
    group?.category.id,
    group?.name,
    groupId,
    incrementClickCount,
    push,
  ]);

  const renderButton = useMemo(() => {
    if (!isJoined || isNotMember(groupMe.role)) {
      const handleJoinChallenge = () => {
        handleGroupOnly({
          blockGroupOnlyTitle: '모임 가입 안내',
          blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getJoinChallenge,
          onSettled: () => {
            mutateJoinChallengeParticipants({
              groupId: Number(groupId),
              challengeId: Number(challengeId),
            });
            snackbar.create({
              message: `'${challengeDetail.name}'챌린지에 참여했어요.`,
              timeout: 3000,
              type: 'default',
              onClick: () => {
                snackbar.dismiss();
              },
            });
          },
          onSuccess({ joinApprovalRequireReason }) {
            if (joinApprovalRequireReason !== 'none') return;

            trackEvent({
              event: 'click_challenge_certify_join_directly_success',
              params: {
                challengeId,
                challengeName: challengeDetail.name,
                challengeDuration: challengeDetail.certifyIntervalDays,
                challengeFrequency: challengeDetail.certifyIntervalTargetCount,
                hasExampleImage: challengeDetail.images?.length ?? 0 > 0,
                groupId,
                groupName: group?.name,
                categoryId: group?.category.id,
              },
            });
            mutateJoinChallengeParticipants({
              groupId: Number(groupId),
              challengeId: Number(challengeId),
            });
          },
        });
      };

      return (
        <BoxButton onClick={handleJoinChallenge} width={'100%'} size="xlarge">
          <Typography typography="label1Bold" color="onPrimary">
            참여하기
          </Typography>
        </BoxButton>
      );
    }

    const isCertifiedAllThisWeek =
      myCertifyActivity?.intervalCertifiedProgress?.current &&
      myCertifyActivity?.intervalCertifiedProgress?.target &&
      myCertifyActivity?.intervalCertifiedProgress?.current >=
        myCertifyActivity?.intervalCertifiedProgress?.target;

    if (isCertifiedAllThisWeek) {
      return (
        <BoxButton width={'100%'} size="xlarge" isDisabled>
          <Typography typography="label1Bold" color="gray500">
            이번주 인증 완료
          </Typography>
        </BoxButton>
      );
    }
    if (challengeDetail?.todayCertified) {
      return (
        <BoxButton width={'100%'} size="xlarge" isDisabled>
          <Typography typography="label1Bold" color="gray500">
            오늘 인증 완료
          </Typography>
        </BoxButton>
      );
    }

    if (challengeDetail.status === 'prepared') {
      const remainDays = differenceInDays(
        new Date(new Date(challengeDetail.startedAt).setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(0, 0, 0, 0))
      );
      return (
        <BoxButton width={'100%'} size="xlarge" isDisabled>
          <Typography typography="label1Bold" color="gray600">
            {remainDays}일 후 인증 가능
          </Typography>
        </BoxButton>
      );
    }

    return (
      <BoxButton onClick={handleWriteCertificationPost} width={'100%'} size="xlarge">
        <Typography typography="label1Bold" color="onPrimary">
          인증글 남기기
        </Typography>
      </BoxButton>
    );
  }, [
    isJoined,
    groupMe.role,
    myCertifyActivity?.intervalCertifiedProgress,
    challengeDetail?.todayCertified,
    challengeDetail.status,
    challengeDetail.name,
    challengeDetail.certifyIntervalDays,
    challengeDetail.certifyIntervalTargetCount,
    challengeDetail.images?.length,
    challengeDetail.startedAt,
    handleWriteCertificationPost,
    handleGroupOnly,
    mutateJoinChallengeParticipants,
    groupId,
    challengeId,
    snackbar,
    group?.name,
    group?.category.id,
  ]);

  if (challengeDetail.status === 'closed') return null;

  if (groupMe.state === 'applied') {
    const handleJoinCancel = () => {
      openDialog({
        element: (
          <Dialog
            title="가입 신청 취소"
            description="가입 신청을 그만둘까요?"
            primaryActionLabel="그만두기"
            secondaryActionLabel="취소"
            onSecondaryAction={closeDialog}
            onPrimaryAction={async () => {
              await closeDialog();
              await deleteApplicationMutate(Number(groupId));
            }}
          />
        ),
      });
    };

    return (
      <div className={s.AppliedContainer}>
        <Typography typography="label3Bold" color="gray700" className={s.AppliedText}>
          가입 승인 대기중이에요
        </Typography>
        <BoxButton onClick={handleJoinCancel} size="medium" variant="secondary">
          <Typography typography="label3Bold" color="gray900">
            신청 취소
          </Typography>
        </BoxButton>
      </div>
    );
  }

  return (
    <div className={s.OuterContainer}>
      <HelpBubbleTrigger
        title={`챌린지 인증글 남기고\n당근머니 받아보세요 🎁`}
        isOpen={isOpenPromotionTooltip}
        portalled={false}
        UNSAFE_style={{
          whiteSpace: 'pre-wrap',
        }}
        positioning={{
          placement: 'top',
        }}
      >
        <div className={s.HelpBubbleInner} />
      </HelpBubbleTrigger>
      <div className={s.Container}>{renderButton}</div>
    </div>
  );
};

export default ChallengeAccessoryBar;
