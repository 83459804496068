import { IconPenHorizlineFill, IconPlusFill, IconXmarkFill } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { AnimatePresence, motion } from 'framer-motion';

import * as s from './index.css';

const FLOATING_BUTTON = {
  DEFAULT: {
    icon: (size: number) => <IconPlusFill size={size} color={vars.$static.color.staticWhite} />,
    text: '모임 만들기',
  },
  MEETUP_CREATE: {
    icon: (size: number) => <IconPlusFill size={size} color={vars.$static.color.staticWhite} />,
    text: '일정 만들기',
  },
  POST: {
    icon: (size: number) => (
      <IconPenHorizlineFill size={size} color={vars.$static.color.staticWhite} />
    ),
    text: '글쓰기',
  },
};

type Props = {
  onClick: () => void;
  type?: keyof typeof FLOATING_BUTTON;
  hideText?: boolean;
  text?: string;
};

export const FloatingIcon = ({ onClick, type = 'DEFAULT', hideText }: Props) => {
  return (
    <div className={s.Wrapper}>
      <motion.button
        className={s.ButtonWrapper}
        onClick={onClick}
        style={{
          padding: hideText ? '1rem' : '0.75rem 1.125rem',
          height: hideText ? '3.5rem' : '100%',
        }}
      >
        <motion.div className={s.ButtonTextWrapper}>
          {FLOATING_BUTTON[type].icon(hideText ? 24 : 16)}

          <>
            {!hideText && <div style={{ width: '4px' }} />}
            <AnimatePresence>
              <motion.div
                variants={container}
                className={s.ButtonText}
                animate={hideText ? 'hidden' : 'show'}
                initial="show"
              >
                <motion.div variants={textVariants}>{FLOATING_BUTTON[type].text}</motion.div>
              </motion.div>
            </AnimatePresence>
          </>
        </motion.div>
      </motion.button>
    </div>
  );
};

const container = {
  hidden: {
    width: 0,
    opacity: 0,
    transition: {
      delayChildren: 0,
    },
  },
  show: {
    width: 'auto',
    height: 'auto',
    transition: {
      delayChildren: 0.2,
    },
  },
};
const textVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
};
