import { Image } from '@community-group/api/lib/group/models';
import type { OriginImage } from '@community-group/components';
import { TextButton, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getMeetupCommentPath, getPostCommentPath, useGetComment } from '@/api/hooks/useGetComment';
import { getMeetupCommentsPath, getPostCommentsPath } from '@/api/hooks/useGetComments';
import { getUserProfileCommentsPath } from '@/api/hooks/useGetUserProfileComments';
import { usePutComment } from '@/api/hooks/usePutComment';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { queryClient } from '@/shared/api/instance';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { commentFormSchema } from '@/utils/validate/formSchema/comment';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

import CommentEditForm from '../components/CommentEditForm';
import GroupCommentFormAccessoryBar from '../components/CommentEditFormAccessoryBar';

type Params = Pick<PageParams, 'groupId' | 'relatedId' | 'commentId' | 'relatedContentType'>;

type FormDataType = {
  content?: string;
  mentionedUserIds?: number[];
  prevImages?: Image[];
  images?: OriginImage[];
  originImages?: OriginImage[];
};

const CommentEditPage: ActivityComponentType<Params> = () => {
  const { groupId = '', relatedId = '', commentId = '', relatedContentType } = usePathParams();
  const { data: group } = useReadGroupDetail(groupId);

  const { data } = useGetComment({
    groupId,
    relatedId,
    commentId,
    relatedContentType,
  });
  const comment = data?.data.comment;
  const initialImages = (comment?.images ?? []).map((image) => ({
    id: image.id,
    image: image.medium,
  }));

  const { pop } = useFlow();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutatePutComment, isPending } = usePutComment({
    relatedContentType,
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      if (relatedContentType === 'post') {
        queryClient.refetchQueries({
          queryKey: [getPostCommentsPath(groupId, relatedId)],
        });
        queryClient.refetchQueries({
          queryKey: [getPostCommentPath(groupId, relatedId, commentId)],
        });
      } else {
        queryClient.refetchQueries({
          queryKey: [getMeetupCommentsPath(groupId, relatedId)],
        });
        queryClient.refetchQueries({
          queryKey: [getMeetupCommentPath(groupId, relatedId, commentId)],
        });
      }
      queryClient.refetchQueries({
        queryKey: [getUserProfileCommentsPath(Number(groupId), Number(comment?.author?.id))],
      });
      pop();
    },
  });

  const formMethods = useForm<FormDataType>({
    defaultValues: {
      content: comment?.content,
      prevImages: comment?.images,
      images: initialImages,
      originImages: initialImages,
    },
  });
  const { watch } = formMethods;

  const handleSubmit = () => {
    const content = watch('content') ?? '';
    const imageIds = (watch('images') ?? []).map((image) => image.id);

    const isValidate = validateSchemaWithHandleToast(commentFormSchema, {
      content,
      images: imageIds,
    });

    if (!isValidate) {
      return false;
    }

    const mentionedUserIds = watch('mentionedUserIds') ?? [];
    const request = {
      groupId,
      relatedId,
      commentId,
      commentModifyForm: {
        content,
        mentionedUserIds,
        images: imageIds,
      },
    };

    mutatePutComment(request, {
      onSuccess: () => {
        trackEvent({
          event: 'click_modify_comment',
          params: {
            groupId,
            postId: relatedId,
            commentId,
          },
        });
      },
    });
  };

  const { content, images = [] } = watch();
  const isFormDataInvalid = useMemo(() => {
    if (!content) {
      return true;
    }

    if (images.some(({ id }) => id.includes('temp') || id.includes('error'))) {
      return true;
    }

    return false;
  }, [content, images]);

  const textareaParentRef = useRef<HTMLDivElement>(null);
  const mentionSuggestionsRef = useRef<HTMLDivElement>(null);

  const handleFocusTextarea = (cursorEnd = true) => {
    setTimeout(() => {
      const textarea: HTMLInputElement | null | undefined =
        textareaParentRef?.current?.querySelector('[data-name="MentionsInput"]');
      const end = textarea?.textContent?.length;

      if (cursorEnd) {
        textarea?.setSelectionRange(end as number | null, end as number | null);
      }

      textarea?.focus();
    });
  };

  const handleBlurTextarea = () => {
    const textarea: HTMLInputElement | null | undefined = textareaParentRef?.current?.querySelector(
      '[data-name="MentionsInput"]'
    );
    textarea?.blur();
  };

  return (
    <FormProvider {...formMethods}>
      <AppScreen
        appBar={{
          title: group?.name ?? '',
          renderRight: () => (
            <TextButton
              isDisabled={isFormDataInvalid || isPending}
              variant="secondary"
              UNSAFE_style={{ padding: '0.5rem' }}
              onClick={handleSubmit}
            >
              완료
            </TextButton>
          ),
        }}
        accessoryBar={
          <GroupCommentFormAccessoryBar
            handleBlurTextarea={handleBlurTextarea}
            handleFocusTextarea={handleFocusTextarea}
            mentionSuggestionsRef={mentionSuggestionsRef}
          />
        }
      >
        <CommentEditForm
          textareaParentRef={textareaParentRef}
          mentionSuggestionsRef={mentionSuggestionsRef}
        />
      </AppScreen>
    </FormProvider>
  );
};

export default withAsyncBoundary(CommentEditPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
