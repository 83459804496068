import {
  GroupAvatar,
  HorizontalSpacing,
  isHigherManager,
  Render,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import {
  IconCheckmarkFlowerFill,
  IconCrownFill,
  IconLocationpinFill,
  IconPersonShieldFill,
} from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';

import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupDirectChatState } from '@/domain/GroupDirectChat/hooks/useReadGroupDirectChatState';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import GroupProfileTemperature from '@/domain/GroupProfile/components/Temperature';
import { useQueryGroupProfileUserInfo } from '@/domain/GroupProfile/hooks/useReadGroupProfileUserInfo';
import GroupDirectChatButtonV2 from '@/features/GroupDirectChat/GroupDirectChatButton';
import GroupDirectChatFallbackButton from '@/features/GroupDirectChat/GroupDirectChatButton/GroupDirectChatFallbackButton';
import SnsTagSection from '@/features/GroupProfile/SnsTagSection';
import GroupProfileEditButton from '@/features/GroupProfile/UserInfo/GroupProfileEditButton';
import ProfileImageWithHandler from '@/features/GroupProfile/UserInfo/ProfileImageWithHandler';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { PageParams } from '@/stackflow/types/params';

import GroupProfileMemberGradeButton from './GroupProfileMemberGradeButton';
import * as s from './style.css';

type Props = {
  groupId: string;
  userId: string;
  from: PageParams['from'];
};
const GroupProfileUserInfo = ({ groupId, userId, from }: Props) => {
  const [
    { data: profile },
    { data: me },
    { data: groupDetail },
    { data: directChatState },
    { data: memberGradeStatus },
  ] = useSuspenseQueries({
    queries: [
      useQueryGroupProfileUserInfo({ groupId, userId }),
      useQueryGroupMe({ groupId }),
      useQueryGroupDetail(groupId),
      useQueryGroupDirectChatState({ groupId, userId }),
      useQueryGroupMemberGradeStatus(groupId),
    ],
  });

  const directChatEnabledStatus = directChatState?.directChatEnabledStatus;

  const isMyProfile = useMemo(() => me.id === profile.id, [me, profile]);

  const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
  const memberGradeEnabled = useMemo(() => {
    return Boolean(memberGradeEnabledFeatureFlag) && memberGradeStatus === 'system';
  }, [memberGradeEnabledFeatureFlag, memberGradeStatus]);

  const render1on1ChatButton = useMemo(() => {
    if (!isMyProfile && directChatEnabledStatus !== 'disabled') return true;
    return false;
  }, [directChatEnabledStatus, isMyProfile]);

  useEnterTrackEvent({
    event: 'enter_member_profile',
    params: {
      hasSnsTags: (profile.snsTags && profile?.snsTags?.length > 0) ?? false,
      hasVerifiedInfo: groupDetail.canViewVerifiedUserInfo,
      verifiedInfo: profile?.verifiedUserInfoText,
      role: me.role,
      from,
    },
    sample: true,
  });

  const shouldShowDescription = useMemo(() => {
    if (profile.description) return true;
    if (isMyProfile && !!profile.snsTags) return true;
    if (!isMyProfile && profile.snsTags.length > 0) return true;
    return false;
  }, [isMyProfile, profile]);

  return (
    <section className={s.Wrapper}>
      <div className={s.UserInfo}>
        <div className={s.AvatarWrapper}>
          <Suspense
            fallback={<GroupAvatar size="large" src={profile.profileImage} role={profile.role} />}
          >
            <ProfileImageWithHandler
              groupId={groupId}
              userId={userId}
              profileImage={profile.profileImage}
              role={profile.role}
              currentUser={me}
            />
          </Suspense>
          <GroupProfileTemperature temperature={profile.temperature} />
        </div>
        <HorizontalSpacing size={12} />
        <div className={s.UserNicknameBox}>
          <div className={s.UserNickname}>
            <div className={s.UserWithRole}>
              <Typography typography="title2Bold">{profile.nickname}</Typography>
              <Render condition={isHigherManager(profile.role)}>
                <VerticalSpacing size={4} />
                <IconCrownFill
                  size={14}
                  color={profile.role === 'superHost' ? '#f7be68' : '#30c795'}
                />
                <VerticalSpacing size={2} />
                <Typography typography="subtitle2Regular" color="gray600">
                  {profile.role === 'superHost' ? '모임장' : '운영진'}
                </Typography>
              </Render>
              <Render condition={profile.grade.name === '정회원'}>
                <VerticalSpacing size={4} />
                <IconCheckmarkFlowerFill size={16} color={vars.$scale.color.gray600} />
                <VerticalSpacing size={2} />
                <Typography typography="subtitle2Regular" color="gray600">
                  정회원
                </Typography>
              </Render>
            </div>
            <Render condition={!!profile.subNickname}>
              <Typography color="gray900" typography="subtitle2Regular">
                {profile.subNickname}
              </Typography>
            </Render>
          </div>
        </div>
      </div>
      <div className={s.DescriptionWrapper}>
        <div className={s.Description} style={{ display: shouldShowDescription ? 'flex' : 'none' }}>
          <Render condition={!!profile.description}>
            <Typography
              color="gray900"
              typography="bodyM1Regular"
              whiteSpace="pre-wrap"
              style={{
                lineHeight: '1.625rem',
                maxHeight: 'auto',
              }}
            >
              {profile.description?.trim()}
            </Typography>
          </Render>
          <Render condition={!!profile.snsTags}>
            <SnsTagSection
              snsTags={profile.snsTags}
              isMyProfile={isMyProfile}
              nickname={profile.nickname}
              userState={me.state}
            />
          </Render>
        </div>
        <div className={s.TagGroup}>
          <div className={s.TagItem}>
            <IconLocationpinFill size={16} color={vars.$scale.color.gray600} />
            <Typography color="gray900" typography="caption1Regular">
              {profile.userRegion.town}
              {profile.userRegionCheckInCount !== 0 && ` 인증 ${profile.userRegionCheckInCount}회`}
            </Typography>
          </div>
          <Render condition={profile.verified}>
            <Typography color="gray700" typography="caption1Regular">
              ⸱
            </Typography>
            <div className={s.TagItem}>
              <IconPersonShieldFill size={16} color={vars.$scale.color.gray600} />
              <Typography color="gray900" typography="caption1Regular">
                본인인증 완료
              </Typography>
            </div>
          </Render>
        </div>
      </div>
      <Render condition={render1on1ChatButton}>
        <Suspense fallback={<GroupDirectChatFallbackButton />}>
          <GroupDirectChatButtonV2
            groupId={groupId}
            targetUserId={userId}
            nickname={profile.nickname}
            requestUserRole={me.role}
          />
        </Suspense>
      </Render>
      <Render condition={isMyProfile}>
        <div className={s.ButtonContainer}>
          <Render condition={memberGradeEnabled}>
            <GroupProfileMemberGradeButton groupId={groupId} userId={userId} />
          </Render>
          <GroupProfileEditButton
            groupId={groupId}
            userId={userId}
            isSubNicknameSettingOn={groupDetail.subNicknameSetting.isSettingOn}
          />
        </div>
      </Render>
    </section>
  );
};

export default GroupProfileUserInfo;
