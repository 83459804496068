import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { isSuperHost, Spacing, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ChipButton } from '@community-group/components';
import { IconPlusLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import ChipRadio from '@/components/common/base/Input/Radio/Chip';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { useGroupDetailContext } from '../GroupDetailContext';
import FeedList from './FeedList';
import * as s from './index.css';

type BoardCategory = {
  key: number;
  value: string;
};

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailFeed = ({ group, currentUser }: Props) => {
  const { selectedBoardKey, setSelectedBoardKey, boardChipAutoScroll } = useGroupDetailContext();
  const { data: boardCategories } = useGetBoardTypeList(group.id);
  const { push } = useFlow();

  const options = useMemo(() => {
    const defaultBoards = [{ id: '0-전체', key: 0, value: '전체' }];
    const categories = boardCategories?.boardCategories ?? [];
    const boards = categories.map((item) => ({
      id: `${item.id}-${item.category}`,
      key: item.id,
      value: item.category,
    }));

    return [...defaultBoards, ...boards];
  }, [boardCategories?.boardCategories]);

  const handleBoardChange = (category: BoardCategory) => {
    trackEvent({
      event: 'click_board_category_chip',
      params: {
        groupId: group.id.toString(),
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUser.role,
        version: '2',
        boardName: category.value,
      },
      sample: true,
    });

    setSelectedBoardKey(category.key);
  };

  const handleImpressionThirdItem = () => {
    trackEvent({
      event: 'impression_3_group_feed_item',
      params: {
        groupId: group.id.toString(),
        groupName: group.name,
        role: currentUser.role,
        version: '2',
      },
      sample: true,
    });
  };

  const handleBoardCreate = () => {
    trackEvent({
      event: 'click_board_category_add',
      params: {
        groupId: group.id.toString(),
        from: 'group_detail_feed',
      },
    });
    push('BoardCategorySetting', {
      groupId: group.id.toString(),
    });
  };

  return (
    <>
      <ChipRadio
        id="group-detail-board-category"
        label=""
        onChange={handleBoardChange}
        selectedOptionKey={selectedBoardKey}
        options={options}
        autoScroll={boardChipAutoScroll}
        appendRightButton={
          isSuperHost(currentUser.role) && (
            <ChipButton
              onClick={handleBoardCreate}
              UNSAFE_style={{
                color: vars.$scale.color.gray600,
              }}
              prefix={<IconPlusLine size={16} color={vars.$scale.color.gray400} />}
            >
              추가
            </ChipButton>
          )
        }
      />

      <Spacing size={8} />
      <div className={s.FeedWrapper}>
        <FeedList
          group={group}
          currentUser={currentUser}
          selectedCategoryValue={selectedBoardKey}
          impressionItemIndex={2}
          onImpressionItem={handleImpressionThirdItem}
        />
      </div>
    </>
  );
};

export default withAsyncBoundary(GroupDetailFeed, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
