import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import GroupSettingBase from '@/features/GroupSetting/Base';
import HideScrollBar from '@/shared/components/AppScreen/components/HideScrollBar';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type GroupSettingV2PageParams = Pick<PageParams, 'groupId' | 'scrollToBottom'>;

const GroupSettingPage: ActivityComponentType<GroupSettingV2PageParams> = () => {
  const { groupId } = usePathParams();
  const { scrollToBottom } = useQueryParams();

  return (
    <AppScreen
      appBar={{
        title: '모임 설정',
        renderRight: () => <IconMagnifyingglassLine />,
        backgroundColor: vars.$semantic.color.paperContents,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <HideScrollBar style={{ backgroundColor: vars.$semantic.color.paperContents }}>
          <GroupSettingBase groupId={groupId} scrollToBottom={scrollToBottom} />
        </HideScrollBar>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupSettingPage;
