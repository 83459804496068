import {
  BoxButton,
  ChipToggleButton,
  isMember,
  Render,
  Typography,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { IconPlusLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Suspense, useEffect, useMemo, useState } from 'react';

import { MEETUP_PROGRESS_STATUS_FILTER, useGetMeetupList } from '@/api/hooks/useGetMeetupList';
import { LoadMoreGroupMeetupContainer } from '@/components/common/LoadMoreContainer';
import { convertFlattenMeetupLisFromAPI as convertFlattenMeetupListFromAPI } from '@/components/group/Detail/components/Meetup/utils/meetupList';
import { useQueryGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useQueryGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import { useQueryGroupMemberGrades } from '@/domain/GroupMember/hooks/useReadGroupMemberGrades';
import { useQueryGroupMemberGradeStatus } from '@/domain/GroupMember/hooks/useReadGroupMemberGradeStatus';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupDetailFeedMeetupList from '../GroupDetailFeedMeetupList';
import * as s from './FeedMeetupList.css';

type Props = {
  groupId: string;
  shouldSetSubNickname: boolean;
};

const FeedMeetupList = ({ groupId, shouldSetSubNickname }: Props) => {
  const { push } = useFlow();

  const [{ data: me }, { data: group }, { data: memberGradeStatus }, { data: memberGrades }] =
    useSuspenseQueries({
      queries: [
        useQueryGroupMe({ groupId, refetchOnWindowFocus: true }),
        useQueryGroupDetail(groupId),
        useQueryGroupMemberGradeStatus(groupId),
        useQueryGroupMemberGrades(groupId),
      ],
    });

  const memberGradeEnabledFeatureFlag = useFeatureFlag('memberGradeEnabled');
  const memberGradeEnabled = useMemo(() => {
    return Boolean(memberGradeEnabledFeatureFlag) && memberGradeStatus === 'system';
  }, [memberGradeEnabledFeatureFlag, memberGradeStatus]);

  const [isEmpty, setIsEmpty] = useState(true);
  const [canJoinAnyMemberGrade, setCanJoinAnyMemberGrade] = useState(true);
  const joinableMinGroupMemberGradeId = useMemo(() => {
    return memberGrades?.find((grade) => grade.name === '정회원')?.id;
  }, [memberGrades]);

  const { data: canNotJoinAnyMemberMeetups } = useGetMeetupList({
    groupId,
    progressStatuses: MEETUP_PROGRESS_STATUS_FILTER.OPENED,
    joinableMinGroupMemberGradeId: joinableMinGroupMemberGradeId,
    order: 'meet_at_asc',
    limit: 1,
  });
  const hasCanNotJoinAnyMemberMeetup = useMemo(() => {
    const meetups = convertFlattenMeetupListFromAPI(canNotJoinAnyMemberMeetups);
    return meetups.length > 0;
  }, [canNotJoinAnyMemberMeetups]);

  const handleWriteButton = () => {
    trackEvent({
      event: 'click_group_meetup_create',
      params: {
        groupId,
        groupName: group.name,
        categoryId: group.category.id,
        categoryName: group.category.name,
        name: 'meetup_section',
      },
    });

    return push('GroupMeetupNewPage', { groupId });
  };

  return (
    <>
      <Render condition={isMember(me.role)}>
        <MeetupWriteButton onClick={handleWriteButton} />
      </Render>
      <Render condition={memberGradeEnabled && !isEmpty && hasCanNotJoinAnyMemberMeetup}>
        <div className={s.ChipButtonContainer}>
          <ChipToggleButton
            isSelected={canJoinAnyMemberGrade}
            onClick={() => {
              trackEvent({
                event: 'click_meetup_list_chip',
                params: {
                  groupId,
                  groupName: group.name,
                  categoryId: group.category.id,
                  categoryName: group.category.name,
                  canJoinAnyMember: true,
                },
              });
              setCanJoinAnyMemberGrade(true);
            }}
          >
            전체
          </ChipToggleButton>
          <ChipToggleButton
            isSelected={!canJoinAnyMemberGrade}
            onClick={() => {
              trackEvent({
                event: 'click_meetup_list_chip',
                params: {
                  groupId,
                  groupName: group.name,
                  categoryId: group.category.id,
                  categoryName: group.category.name,
                  canJoinAnyMember: false,
                },
              });
              setCanJoinAnyMemberGrade(false);
            }}
          >
            정회원
          </ChipToggleButton>
        </div>
      </Render>
      <Suspense fallback={<ViewLoader />}>
        <FeedMeetupListCore
          groupId={groupId}
          shouldSetSubNickname={shouldSetSubNickname}
          setIsEmpty={setIsEmpty}
          joinableMinGroupMemberGradeId={
            canJoinAnyMemberGrade ? undefined : joinableMinGroupMemberGradeId
          }
        />
      </Suspense>
    </>
  );
};

type CoreProps = {
  setIsEmpty: (isEmpty: boolean) => void;
  joinableMinGroupMemberGradeId?: string;
} & Props;

const FeedMeetupListCore = ({
  groupId,
  shouldSetSubNickname,
  setIsEmpty,
  joinableMinGroupMemberGradeId,
}: CoreProps) => {
  const { data: openedMeetupData, hasNextPage: hasOpenedMeetupNextPage } = useGetMeetupList({
    groupId,
    progressStatuses: MEETUP_PROGRESS_STATUS_FILTER.OPENED,
    order: 'meet_at_asc',
    joinableMinGroupMemberGradeId,
  });
  const openedMeetups = useMemo(
    () => convertFlattenMeetupListFromAPI(openedMeetupData),
    [openedMeetupData]
  );

  const { data: closedMeetupData, hasNextPage: hasClosedMeetupNextPage } = useGetMeetupList({
    groupId,
    progressStatuses: MEETUP_PROGRESS_STATUS_FILTER.CLOSED,
    order: 'meet_at_desc',
    joinableMinGroupMemberGradeId,
  });

  const closedMeetups = useMemo(
    () => convertFlattenMeetupListFromAPI(closedMeetupData),
    [closedMeetupData]
  );

  const isEmpty = useMemo(() => {
    if (hasOpenedMeetupNextPage) return false;
    if (openedMeetups.length > 0) return false;

    if (hasClosedMeetupNextPage) return false;
    if (closedMeetups.length > 0) return false;

    return true;
  }, [openedMeetups, hasOpenedMeetupNextPage, closedMeetups, hasClosedMeetupNextPage]);

  useEffect(() => {
    setIsEmpty(isEmpty);
  }, [isEmpty, setIsEmpty]);

  if (isEmpty) {
    return (
      <div className={s.EmptyListWrapper}>
        <Typography className={s.EmptyList} typography="bodyM1Regular" color="gray600">
          아직 일정이 없어요.
          <br />
          가장 먼저 일정을 만들어보세요.
        </Typography>
      </div>
    );
  }

  return (
    <>
      <GroupDetailFeedMeetupList
        title="다가오는 일정"
        groupId={groupId}
        meetups={openedMeetups}
        shouldSetSubNickname={shouldSetSubNickname}
      />
      {hasOpenedMeetupNextPage ? (
        <LoadMoreGroupMeetupContainer
          selectedMeetupProgressStatusKey={'OPENED'}
          order={'meet_at_asc'}
        />
      ) : (
        <>
          <GroupDetailFeedMeetupList
            title="종료된 일정"
            groupId={groupId}
            meetups={closedMeetups}
            shouldSetSubNickname={shouldSetSubNickname}
            shouldReverse
          />
          {hasClosedMeetupNextPage && (
            <LoadMoreGroupMeetupContainer
              selectedMeetupProgressStatusKey={'CLOSED'}
              order={'meet_at_desc'}
            />
          )}
        </>
      )}
    </>
  );
};

type ButtonProps = {
  onClick: () => void;
};

const MeetupWriteButton = ({ onClick }: ButtonProps) => {
  return (
    <div className={s.ButtonWrapper}>
      <BoxButton
        size="large"
        variant="secondary"
        width="100%"
        prefix={<IconPlusLine size={16} color={vars.$scale.color.gray900} />}
        onClick={onClick}
      >
        일정 만들기
      </BoxButton>
    </div>
  );
};

export default withAsyncBoundary(FeedMeetupList, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
