import {
  isHigherManager as checkIsHigherManager,
  withAsyncBoundary,
} from '@community-group/components';
import { useMemo } from 'react';

import { useReadGroupMe } from '@/domain/GroupDetail/hooks/useReadGroupMe';
import useChallengeCertifyPromotion from '@/hooks/useChallengeCertifyPromotion';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import useGroupDetailGroupChallengeCertifyPromotionBannerClosed from '../hooks/useGroupDetailGroupChallengeCertifyPromotionBannerClosed';
import useGroupDetailHeroImageBannerClosed from '../hooks/useGroupDetailHeroImageBannerClosed';
import useGroupDetailMemberGradeNoticeBannerClosed from '../hooks/useGroupDetailMemberGradeNoticeBannerClosed';
import GroupDetailGroupChallengeCertifyPromotionBanner from './GroupDetailGroupChallengeCertifyPromotionBanner';
import GroupDetailHeroSliderBanner from './GroupDetailHeroSliderBanner';
import GroupDetailMemberGradeNoticeBanner from './GroupDetailMemberGradeNoticeBanner';

type Props = { groupId: string };

function GroupDetailBanner({ groupId }: Props) {
  const { data: groupMe } = useReadGroupMe({ groupId });
  const memberGradeEnabled = useFeatureFlag('memberGradeEnabled');

  const enableChallengePromotion = useChallengeCertifyPromotion('enableChallengePromotion');
  const currentUserRole = useMemo(() => groupMe.role ?? 'none', [groupMe]);
  const isHigherManager = useMemo(() => checkIsHigherManager(currentUserRole), [currentUserRole]);
  const isMember = useMemo(() => currentUserRole !== 'none', [currentUserRole]);
  const isSuperHost = useMemo(() => currentUserRole === 'superHost', [currentUserRole]);

  const [groupChallengeCertifyPromotionBannerClosed] =
    useGroupDetailGroupChallengeCertifyPromotionBannerClosed(groupId);

  const [memberGradeNoticeBannerClosed] = useGroupDetailMemberGradeNoticeBannerClosed(groupId);
  const [heroImageBannerClosed] = useGroupDetailHeroImageBannerClosed(groupId);

  // 멤버 등급제 FeatureFlag 활성화 상태라면 모임장에게 배너 노출
  if (memberGradeEnabled && isSuperHost && !memberGradeNoticeBannerClosed) {
    return <GroupDetailMemberGradeNoticeBanner groupId={groupId} />;
  }

  // 히어로 슬라이더 관련 사진 첨부 or 게시글 작성 안내 배너
  if (isHigherManager && !heroImageBannerClosed) {
    return <GroupDetailHeroSliderBanner groupId={groupId} />;
  }

  // 2025.01 챌린지 인증 프로모션 이벤트 > 모임 상세 배너
  if (enableChallengePromotion && isMember && !groupChallengeCertifyPromotionBannerClosed) {
    return <GroupDetailGroupChallengeCertifyPromotionBanner groupId={groupId} />;
  }

  return null;
}

export default withAsyncBoundary(GroupDetailBanner, {
  pendingFallback: null,
  rejectedFallback: <></>,
});
