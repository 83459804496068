import {
  ActionList,
  ActionListItem,
  ActionSheet,
  Dialog,
  useBottomSheet,
  useDialog,
  useSnackbarAdapter,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useDeleteGroupMeetupDetail } from '@/api/hooks/useDeleteGroupMeetupDetail';
import { useDeletePost } from '@/api/hooks/useDeletePost';
import { getGroupFeedType } from '@/components/group/Detail/utils/getGroupFeedType';
import { useDeleteChallenge } from '@/domain/Challenge/hooks/useDeleteChallenge';
import usePatchChallenge from '@/domain/Challenge/hooks/usePatchChallenge';
import { useReadGroupDetail } from '@/domain/Group/hooks/useReadGroupDetail';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupChallenge } from '@/utils/refetch/challenge';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import { FeedModalProps } from './FeedModal';

const WriterRoleOptionsList = ({
  groupId,
  post,
  onEditPost,
  onDeletePost,
  onEditPostBoardCategory,
}: FeedModalProps) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { create, dismiss } = useSnackbarAdapter();

  const { data: group } = useReadGroupDetail(groupId);
  const { open: openDialog, close: closeDialog } = useDialog();
  const { mutate: mutateDeletePost } = useDeletePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({ groupId });
    },
  });
  const { mutate: mutateDeleteGroupMeetup } = useDeleteGroupMeetupDetail({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({ groupId });
    },
  });
  const { mutate: patchChallenge } = usePatchChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      closeBottomSheet();
      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId: post?.challengeInfo?.id.toString() });
      create({
        message: '챌린지가 종료되었어요.',
        timeout: 3000,
        type: 'default',
        onClick: dismiss,
      });
    },
  });

  const { mutate: deleteChallenge } = useDeleteChallenge({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_delete_challenge',
        params: {
          challengeId: post?.challengeInfo?.id,
          challengeName: post?.challengeInfo?.name,
          challengeStatus: post?.challengeInfo?.status,
          groupId,
          groupName: group?.name,
          categoryId: group?.category.id,
        },
      });

      refetchGroupDetail({ groupId });
      refetchGroupChallenge({ groupId, challengeId: post?.challengeInfo?.id.toString() });

      create({
        message: '챌린지가 삭제되었어요.',
        timeout: 3000,
        type: 'default',
        onClick: dismiss,
      });
    },
  });

  const handleCloseChallenge = () => {
    openDialog({
      element: (
        <Dialog
          title="챌린지를 종료할까요?"
          description="이웃이 더이상 챌린지에 참여할 수 없고, 수정할 수 없어요."
          onPrimaryAction={async () => {
            await closeDialog();
            if (!post.challengeInfo?.id) return;
            patchChallenge({
              id: Number(groupId),
              challengeId: Number(post.challengeInfo?.id),
              form: {
                status: 'closed',
              },
            });
          }}
          primaryActionLabel="종료"
          onSecondaryAction={closeDialog}
          secondaryActionLabel="취소"
        />
      ),
    });
  };

  const handleDeleteChallenge = (challengeId: number) => {
    openDialog({
      element: (
        <Dialog
          title="챌린지를 삭제할까요?"
          description="모든 데이터가 삭제되고 다시 볼 수 없어요."
          primaryActionLabel="삭제"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            await closeDialog();
            deleteChallenge({
              groupId,
              challengeId: challengeId.toString(),
            });
          }}
          onSecondaryAction={async () => {
            trackEvent({
              event: 'click_cancel',
              params: {
                type: 'delete',
              },
            });
            await closeDialog();
          }}
        />
      ),
    });
  };

  const feedType = useMemo(() => getGroupFeedType(post.postType), [post.postType]);

  const handleDeletePostDialog = async () => {
    await closeBottomSheet();

    if (post.challengeInfo?.id && post.postType?.type === 'challenge') {
      handleDeleteChallenge(post.challengeInfo.id);
      return;
    }

    openDialog({
      element: (
        <Dialog
          title="게시글을 삭제할까요?"
          description="모든 데이터가 삭제되고 다시 볼 수 없어요."
          primaryActionLabel="삭제"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            if (onDeletePost) return onDeletePost();

            await closeDialog();

            if (feedType === 'meetup' && post.meetupInfo?.id) {
              await mutateDeleteGroupMeetup(
                { groupId, meetupId: String(post.meetupInfo?.id) },
                {
                  onSuccess: () => {
                    trackEvent({
                      event: 'click_delete',
                      params: {
                        type: 'meetup',
                      },
                    });
                  },
                }
              );
              return;
            }

            await mutateDeletePost(
              { groupId, postId: String(post.id) },
              {
                onSuccess: () => {
                  trackEvent({
                    event: 'click_delete',
                    params: {
                      type: 'post',
                    },
                  });
                },
              }
            );
          }}
          onSecondaryAction={async () => {
            trackEvent({
              event: 'click_cancel',
              params: {
                type: 'delete',
              },
            });
            await closeDialog();
          }}
        />
      ),
    });
  };

  const challengeClosePermission =
    post.postType?.type === 'challenge' && post.challengeInfo?.status !== 'closed';

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        {feedType !== 'meetup' && feedType !== 'challenge' && group?.isBoardManaged && (
          <ActionListItem onClick={onEditPostBoardCategory}>다른 게시판으로 이동</ActionListItem>
        )}
        <ActionListItem
          onClick={async () => {
            if (onEditPost) return onEditPost();
          }}
        >
          수정
        </ActionListItem>
        {challengeClosePermission && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();
              handleCloseChallenge();
            }}
          >
            챌린지 종료
          </ActionListItem>
        )}
        <ActionListItem
          onClick={() => {
            handleDeletePostDialog();
          }}
          color={vars.$semantic.color.danger}
        >
          삭제
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};

export default WriterRoleOptionsList;
