import { useXproperty } from './useXproperty';

// !프로덕션에 정의했는지 확인하기
// 프로덕션 : https://xproperty.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR
// 알파 : https://xproperty.alpha.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR

// 사용중인 피쳐플래그 타입
export type FeatureFlagKeysType = {
  // 12월 덕질 마케팅 프로모션
  shown12FandomMarketingPromotion?: boolean;

  parentingGroupNotMemberBannerEnabled?: boolean; // 육아 모임 미가입자 배너 노출 여부
  memberGradeEnabled?: boolean; // 멤버 등급 사용 여부
  newFeedChallengeBanner?: boolean; // 피드의 챌린지 게시글 내부 새로운 챌린지 배너 사용 여부

  enableChallengePromotion?: boolean; // 25년1월 챌린지 프로모션 시작 여부 - 이벤트 분기용

  enableHomeChallengePromotionTab?: boolean; // 홈 탭 > 챌린지 프로모션 이벤트 탭 노출 여부

  enableMyChallengeResultPage?: boolean; // 챌린지 인증 후 나의 인증 결과 페이지 노출 여부

  enableChallengeCertifyNextStep?: boolean; // 챌린지 인증 후 결과 페이지 - 선배포 이후 피쳐 오픈여부
};

type FeatureFlagType = keyof FeatureFlagKeysType;

export const useFeatureFlag = <T extends FeatureFlagType>(
  featureFlagName: T
): FeatureFlagKeysType[T] | false => {
  const xProperty = useXproperty();

  return xProperty?.communityGroupWebFeatureFlags?.[featureFlagName] ?? false;
};
