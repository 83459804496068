import { AsyncBoundary, BoxButton, Divider, Spacing } from '@community-group/components';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import {
  initMeetupMemberReviewCreateFormData,
  initMeetupReviewCreateFormData,
  meetupMemberReviewCreateFormSchema,
  meetupReviewCreateFormSchema,
} from '@/utils/validate/formSchema/meetupReview';

import MeetupDetailHeader from '../components/common/MeetupDetailHeader/MeetupDetailHeader';
import { MeetupDetailHeaderSkeleton } from '../components/common/MeetupDetailHeader/Skeleton';
import { MeetupReviewWrittenHandling } from '../components/common/ReviewWrittenHandling';
import { MeetupMemberReviewForm } from '../components/MeetupMemberReviewForm';
import { MeetupReviewForm } from '../components/MeetupReviewForm';
import { useGroupMeetupCreateReviewForm } from '../hooks';
import * as s from './style.css';

export type GroupMeetupCreateReviewPageParams = Pick<PageParams, 'groupId' | 'meetupId'>;

const GroupMeetupCreateReviewPage: ActivityComponentType<
  GroupMeetupCreateReviewPageParams
> = () => {
  const back = useBack();
  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={back}>
              <IconXmarkLine />
            </div>
          ),
        },
      }}
      disabledClientOnly={true}
    >
      <GroupMeetupCreateReviewView />
    </AppScreen>
  );
};

const GroupMeetupCreateReviewView = () => {
  const { groupId, meetupId } = usePathParams();
  const { replace } = useFlow();

  useSetFixedLayoutSize();

  useEnterTrackEvent({
    event: 'enter_write_group_meetup_review_page',
    params: {
      groupId,
      meetupId,
    },
  });

  const meetupReviewFormHandler = useForm({
    defaultValues: initMeetupReviewCreateFormData,
    resolver: zodResolver(meetupReviewCreateFormSchema),
  });

  const isSelectedReviewRating = useMemo(
    () => !!meetupReviewFormHandler.watch('reviewRating'),
    [meetupReviewFormHandler.watch('reviewRating')]
  );

  const meetupMemberReviewFormHandler = useForm({
    defaultValues: initMeetupMemberReviewCreateFormData,
    resolver: zodResolver(meetupMemberReviewCreateFormSchema),
  });

  const { isLoading, handleSubmit } = useGroupMeetupCreateReviewForm({
    meetupReviewFormHandler,
    meetupMemberReviewFormHandler,
  });

  const handleSubmitForm = async () => {
    handleSubmit({
      onSuccess: () => {
        const meetupReviewFormData = meetupReviewFormHandler.getValues();
        const meetupMemberReviewFormData = meetupMemberReviewFormHandler.getValues();

        trackEvent({
          event: 'click_submit_group_meetup_review',
          params: {
            groupId,
            meetupId,
            reviewRating: meetupReviewFormData.reviewRating,
            hasReviewPost: !!meetupReviewFormData.reviewPost?.content,
            hasMemberReviewMessage: !!meetupMemberReviewFormData.feedbackMembers.find(
              (feedback) => !!feedback?.review
            ),
            positiveFeedbackMemberCount: meetupMemberReviewFormData.feedbackMembers.filter(
              ({ feedbackRating }) => feedbackRating === 'LIKE'
            ).length,
            negativeFeedbackMemberCount: meetupMemberReviewFormData.feedbackMembers.filter(
              ({ feedbackRating }) => feedbackRating === 'DISLIKE'
            ).length,
          },
        });

        replace('GroupMeetupCreateDoneReviewPage', { groupId });
        refetchGroupDetail({ groupId });
        refetchGroupMeetupDetail({ groupId, meetupId });
      },
    });
  };

  const handleSubmitClick = async () => {
    if (isLoading) return;

    handleSubmitForm();
  };

  return (
    <>
      <AsyncBoundary>
        <MeetupReviewWrittenHandling />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<MeetupDetailHeaderSkeleton />}>
        <MeetupDetailHeader />
        <Divider size={1} padding={16} />
      </AsyncBoundary>
      <AsyncBoundary>
        <MeetupReviewForm formHandler={meetupReviewFormHandler} />
      </AsyncBoundary>
      <div style={{ display: isSelectedReviewRating ? 'block' : 'none' }}>
        <AsyncBoundary>
          <MeetupMemberReviewForm formHandler={meetupMemberReviewFormHandler} />
        </AsyncBoundary>
      </div>
      <Spacing size={48} />
      {isSelectedReviewRating && (
        <div className={s.ButtonWrapper}>
          <BoxButton width="100%" size="xlarge" isLoading={isLoading} onClick={handleSubmitClick}>
            후기 남기기
          </BoxButton>
        </div>
      )}
    </>
  );
};

export default GroupMeetupCreateReviewPage;
